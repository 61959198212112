import React from "react";

// Import your various keyboard components
import EnglishKeyboard from "../keyboards/EnglishKeyboard";
import HebrewKeyboard from "../keyboards/HebrewKeyboard";
import HindiKeyboard from "../keyboards/HindiKeyboard";
import SpanishKeyboard from "../keyboards/SpanishKeyboard";
import ArabicKeyboard from "../keyboards/ArabicKeyboard";

export default function KeyboardManager({ language, pressedKey, isCorrect }) {
  // Function that picks which keyboard to render
  function renderKeyboard() {
    switch (language) {
      case "Hebrew":
        return <HebrewKeyboard pressedKey={pressedKey} isCorrect={isCorrect} />;
      case "Hindi":
        return <HindiKeyboard pressedKey={pressedKey} isCorrect={isCorrect} />;
      case "Spanish":
        return <SpanishKeyboard pressedKey={pressedKey} isCorrect={isCorrect} />;
      case "Arabic":
        return <ArabicKeyboard pressedKey={pressedKey} isCorrect={isCorrect} />;
      default: // English by default
        return <EnglishKeyboard pressedKey={pressedKey} isCorrect={isCorrect} />;
    }
  }

  return (
    <div className="keyboard-manager">
      {/* Render the selected keyboard */}
      <div className="keyboard">{renderKeyboard()}</div>
    </div>
  );
}
