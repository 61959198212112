import React from 'react';

export default function ArabicKeyboard() {
  return (
    <figure className="kbBtnsWrap Arabic">
      {/* Numerics Row */}
      <div className="kbBtnsRow numericsRow montser-mdm d-flex flex-row justify-content-center align-items-center w-100">
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="192"
          data-finger="babyFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">`</span>
            <span className="otherlangChar otherlangFont">ذ</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="49"
          data-finger="babyFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">1</span>
            <span className="otherlangChar otherlangFont">١</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="50"
          data-finger="ringFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">2</span>
            <span className="otherlangChar otherlangFont">٢</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="51"
          data-finger="middleFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">3</span>
            <span className="otherlangChar otherlangFont">٣</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="52"
          data-finger="indexFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">4</span>
            <span className="otherlangChar otherlangFont">٤</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="53"
          data-finger="indexFingerLeft"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">5</span>
            <span className="otherlangChar otherlangFont">٥</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="54"
          data-finger="ringFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">6</span>
            <span className="otherlangChar otherlangFont">٦</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="55"
          data-finger="ringFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">7</span>
            <span className="otherlangChar otherlangFont">٧</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="56"
          data-finger="middleFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">8</span>
            <span className="otherlangChar otherlangFont">٨</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="57"
          data-finger="indexFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">9</span>
            <span className="otherlangChar otherlangFont">٩</span>
          </span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="48"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">0</span>
            <span className="otherlangChar otherlangFont">٠</span>
          </span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="45"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress englishCharFont">_</span>
          <span className="otherlangChar otherlangFont">-</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="61"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress englishCharFont">+</span>
          <span className="otherlangChar otherlangFont">=</span>
        </button>
        <button
          className="kbHero backspaceBtn"
          type="button"
          data-key-code-val="8"
          data-finger="babyFingerRight"
        >
          Backspace
        </button>
      </div>

      {/* Tab Row */}
      <div className="kbBtnsRow tabRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button
          className="kbHero tabBtn"
          type="button"
          data-key-code-val=""
          data-shift-key-code-val="9"
          data-finger="babyFingerLeft"
        >
          Tab
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="113"
          data-shift-key-code-val="81"
          data-finger="babyFingerLeft"
        >
          <span className="englishChar englishCharFont">Q</span>
          <span className="noArabicPress otherlangChar otherlangFont">ض</span>
          <span className="arabicPress d-none">َ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="119"
          data-shift-key-code-val="87"
          data-finger="ringFingerLeft"
        >
          <span className="englishChar englishCharFont">W</span>
          <span className="noArabicPress otherlangChar otherlangFont">ص</span>
          <span className="arabicPress d-none">ً</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="101"
          data-shift-key-code-val="69"
          data-finger="middleFingerLeft"
        >
          <span className="englishChar englishCharFont">E</span>
          <span className="noArabicPress otherlangChar otherlangFont">ث</span>
          <span className="arabicPress d-none">ُ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="114"
          data-shift-key-code-val="82"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">R</span>
          <span className="noArabicPress otherlangChar otherlangFont">ق</span>
          <span className="arabicPress d-none">ٌ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="116"
          data-shift-key-code-val="84"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">T</span>
          <span className="noArabicPress otherlangChar otherlangFont">ف</span>
          <span className="arabicPress d-none">لإ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="121"
          data-shift-key-code-val="89"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">Y</span>
          <span className="noArabicPress otherlangChar otherlangFont">غ</span>
          <span className="arabicPress d-none">إ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="117"
          data-shift-key-code-val="85"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">U</span>
          <span className="noArabicPress otherlangChar otherlangFont">ع</span>
          <span className="arabicPress d-none">،</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="105"
          data-shift-key-code-val="73"
          data-finger="middleFingerRight"
        >
          <span className="englishChar englishCharFont">I</span>
          <span className="noArabicPress otherlangChar otherlangFont">ه</span>
          <span className="arabicPress d-none">÷</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="111"
          data-shift-key-code-val="79"
          data-finger="indexFingerRight"
        >
          <span className="englishChar englishCharFont">O</span>
          <span className="noArabicPress otherlangChar otherlangFont">خ</span>
          <span className="arabicPress d-none">×</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="112"
          data-shift-key-code-val="80"
          data-finger="babyFingerRight"
        >
          <span className="englishChar englishCharFont">P</span>
          <span className="noArabicPress otherlangChar otherlangFont">ح</span>
          <span className="arabicPress d-none">؛</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="91"
          data-shift-key-code-val="123"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">[</span>
            <span className="noArabicPress otherlangChar otherlangFont">ج</span>
            <span className="arabicPress d-none">&lt;</span>
          </span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="93"
          data-shift-key-code-val="125"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">]</span>
            <span className="noArabicPress otherlangChar otherlangFont">د</span>
            <span className="arabicPress d-none">&gt;</span>
          </span>
        </button>
        <button
          className="doubleSigns backSlash"
          type="button"
          data-key-code-val="92"
          data-shift-key-code-val="220"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress englishCharFont">|</span>
          <span className="normalPress otherlangFont">\\</span>
        </button>
      </div>

      {/* Caps Lock Row */}
      <div className="kbBtnsRow capsLockRow montser-mdm  d-flex flex-row justify-content-center align-items-center">
        <button
          className="kbHero capLockBtn"
          type="button"
          data-key-code-val=""
          data-shift-key-code-val="20"
          data-finger="babyFingerLeft"
        >
          Caps Lock
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="97"
          data-shift-key-code-val="65"
          data-finger="babyFingerLeft"
        >
          <span className="englishChar englishCharFont">A</span>
          <span className="noArabicPress otherlangChar otherlangFont">ش</span>
          <span className="arabicPress d-none">ِ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="115"
          data-shift-key-code-val="83"
          data-finger="ringFingerLeft"
        >
          <span className="englishChar englishCharFont">S</span>
          <span className="noArabicPress otherlangChar otherlangFont">س</span>
          <span className="arabicPress d-none">ٍ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="100"
          data-shift-key-code-val="68"
          data-finger="middleFingerLeft"
        >
          <span className="englishChar englishCharFont">D</span>
          <span className="noArabicPress otherlangChar otherlangFont">ي</span>
          <span className="arabicPress d-none">]</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="102"
          data-shift-key-code-val="70"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">F</span>
          <span className="noArabicPress otherlangChar otherlangFont">ब</span>
          {/* This might be a typo in your original snippet: 'ब' is Hindi, but we'll preserve it as given. */}
          <span className="arabicPress d-none">[</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="103"
          data-shift-key-code-val="71"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">G</span>
          <span className="noArabicPress otherlangChar otherlangFont">ل</span>
          <span className="arabicPress d-none">لأ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="104"
          data-shift-key-code-val="72"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">H</span>
          <span className="noArabicPress otherlangChar otherlangFont">ا</span>
          <span className="arabicPress d-none">أ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="106"
          data-shift-key-code-val="74"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">J</span>
          <span className="noArabicPress otherlangChar otherlangFont">ت</span>
          <span className="arabicPress d-none">ـ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="107"
          data-shift-key-code-val="75"
          data-finger="middleFingerRight"
        >
          <span className="englishChar englishCharFont">K</span>
          <span className="noArabicPress otherlangChar otherlangFont">ن</span>
          <span className="arabicPress d-none">،</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="108"
          data-shift-key-code-val="76"
          data-finger="indexFingerRight"
        >
          <span className="englishChar englishCharFont">L</span>
          <span className="noArabicPress otherlangChar otherlangFont">م</span>
          <span className="arabicPress d-none">/</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="59"
          data-shift-key-code-val="58"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">;</span>
            <span className="noArabicPress otherlangChar otherlangFont">ك</span>
            <span className="arabicPress d-none">:</span>
          </span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="39"
          data-shift-key-code-val="34"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">'</span>
            <span className="noArabicPress otherlangChar otherlangFont">ط</span>
            <span className="arabicPress d-none">"</span>
          </span>
        </button>
        <button
          className="kbHero enterBtn"
          type="button"
          data-key-code-val="13"
          data-finger="babyFingerRight"
        >
          Enter
        </button>
      </div>

      {/* Shift Row */}
      <div className="kbBtnsRow shiftRow montser-mdm d-flex flex-row  justify-content-center align-items-center">
        <button
          className="kbHero firstShiftBtn"
          type="button"
          data-shift-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="122"
          data-shift-key-code-val="90"
          data-finger="babyFingerLeft"
        >
          <span className="englishChar englishCharFont">Z</span>
          <span className="noArabicPress otherlangChar otherlangFont">ئ</span>
          <span className="arabicPress d-none">~</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="120"
          data-shift-key-code-val="88"
          data-finger="ringFingerLeft"
        >
          <span className="englishChar englishCharFont">X</span>
          <span className="noArabicPress otherlangChar otherlangFont">ء</span>
          <span className="arabicPress d-none">ْ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="99"
          data-shift-key-code-val="67"
          data-finger="middleFingerLeft"
        >
          <span className="englishChar englishCharFont">C</span>
          <span className="noArabicPress otherlangChar otherlangFont">ؤ</span>
          <span className="arabicPress d-none">&#125;</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="118"
          data-shift-key-code-val="86"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">V</span>
          <span className="noArabicPress otherlangChar otherlangFont">ر</span>
          <span className="arabicPress d-none">{'{'}</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="98"
          data-shift-key-code-val="66"
          data-finger="indexFingerLeft"
        >
          <span className="englishChar englishCharFont">B</span>
          <span className="noArabicPress otherlangChar otherlangFont">لا</span>
          <span className="arabicPress d-none">لأ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="110"
          data-shift-key-code-val="78"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">N</span>
          <span className="noArabicPress otherlangChar otherlangFont">ى</span>
          <span className="arabicPress d-none">آ</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="109"
          data-shift-key-code-val="77"
          data-finger="ringFingerRight"
        >
          <span className="englishChar englishCharFont">M</span>
          <span className="noArabicPress otherlangChar otherlangFont">ة</span>
          <span className="arabicPress d-none">’</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="44"
          data-shift-key-code-val="60"
          data-finger="middleFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">,</span>
            <span className="noArabicPress otherlangChar otherlangFont">و</span>
          </span>
          <span className="arabicPress d-none">,</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="46"
          data-shift-key-code-val="62"
          data-finger="indexFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="englishChar englishCharFont pl-2">.</span>
            <span className="noArabicPress otherlangChar otherlangFont">ز</span>
          </span>
          <span className="arabicPress d-none">.</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="47"
          data-shift-key-code-val="63"
          data-finger="babyFingerRight"
        >
          <span className="normalPress otherlangFont">
            <span className="noArabicPress englishChar englishCharFont pl-2">/</span>
            <span className="noArabicPress otherlangChar otherlangFont">ظ</span>
          </span>
          <span className="arabicPress d-none">؟</span>
        </button>
        <button
          className="kbHero secondShiftBtn arabicShift purple-color"
          type="button"
          data-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
      </div>

      {/* Space Row */}
      <div className="kbBtnsRow spaceRow montser-mdm d-flex flex-row   justify-content-center align-items-center">
        <button
          className="kbHero spaceBtn"
          type="button"
          data-key-code-val="32"
          data-finger="thumb"
        ></button>
      </div>
    </figure>
  );
}