import React, { useState, useEffect, useCallback, useRef } from "react";
import KeyboardManager from "./KeyboardManager";
import "./../css/keyboard.css";
import axios from "axios";
import practiceData from "../data/practiceData.json";
// Import FaceTracker component
import FaceTracker from "./FaceTracker";
// Import CompletionPopup component
import CompletionPopup from "./CompletionPopup";

// Create NameCollectionPopup component
function NameCollectionPopup({ onSubmit }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Simple validation
    if (!firstName.trim() || !lastName.trim()) {
      setError('Please enter both first name and last name.');
      return;
    }
    
    // Clear any error
    setError('');
    
    // Submit user name information
    onSubmit({ firstName, lastName });
  };

  return (
    <div 
      className="position-absolute d-flex flex-column justify-content-center align-items-center"
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.85)",
        zIndex: 99999,
        color: "#ffe6c8",
        textAlign: "center",
        padding: "2rem"
      }}
    >
      <div 
        className="bg-dark p-5 rounded position-relative" 
        style={{ 
          maxWidth: "500px", 
          width: "100%" 
        }}
      >
        <h2 className="mb-4" style={{ fontFamily: 'Montserrat-large', color: "#ffe6c8" }}>Welcome to Practice</h2>
        <p className="mb-4" style={{ fontFamily: 'Montserrat-large', color: "#ffe6c8" }}>
          Please enter your name to begin the practice session.
        </p>
        
        {error && (
          <div className="alert alert-danger mb-3" role="alert">
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label" style={{ fontFamily: 'Montserrat-large', color: "#ffe6c8", textAlign: "left", display: "block" }}>
              First Name
            </label>
            <input
              type="text"
              className="form-control bg-dark"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ 
                color: "#ffe6c8",
                backgroundColor: "#343a40",
                borderColor: "#495057"
              }}
            />
          </div>
          
          <div className="mb-4">
            <label htmlFor="lastName" className="form-label" style={{ fontFamily: 'Montserrat-large', color: "#ffe6c8", textAlign: "left", display: "block" }}>
              Last Name
            </label>
            <input
              type="text"
              className="form-control bg-dark"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{ 
                color: "#ffe6c8",
                backgroundColor: "#343a40",
                borderColor: "#495057"
              }}
            />
          </div>
          
          <button 
            type="submit" 
            className="btn btn-primary btn-lg px-5"
            style={{ fontFamily: 'Montserrat-large' }}
          >
            Start Practice
          </button>
        </form>
      </div>
    </div>
  );
}

function PracticeModal({ isOpen, onClose }) {
  // Add state for name collection popup
  const [showNamePopup, setShowNamePopup] = useState(true);
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
  
  const [selectedLanguage, setSelectedLanguage] = useState("Hindi");
  const [currentPractice, setCurrentPractice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Replace the API text with the content from JSON when it's loaded
  const [text, setText] = useState("שלום! זהו טקסט לדוגמה בעברית. אנו לומדים כיצד להקליד בשפות שונות. מקווה שזה יעזור לך בתרגול שלך.");
  const [characters, setCharacters] = useState(text.split(""));
  
  const [currentIndex, setCurrentIndex] = useState(0); // Track current letter
  const [pressedKey, setPressedKey] = useState(null); // Track pressed key
  const [isCorrect, setIsCorrect] = useState(null); // Track correctness
  const [startTime, setStartTime] = useState(null); // Track when typing started
  const [typingSpeed, setTypingSpeed] = useState({ wpm: 0, cpm: 0 }); // Track typing speed
  const [isTypingStarted, setIsTypingStarted] = useState(false); // Track if typing has started
  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const [wordsTyped, setWordsTyped] = useState(0); // Track number of words typed
  
  // Add state for attention score from face tracker
  const [attentionScore, setAttentionScore] = useState(100);
  
  // Add state for practice completion
  const [isPracticeCompleted, setIsPracticeCompleted] = useState(false);
  
  // Add state for question data and user responses
  const [questionData, setQuestionData] = useState(null);
  const [userResponse, setUserResponse] = useState(null);
  const [responseCorrect, setResponseCorrect] = useState(null);
  
  // Add state for review data
  const [reviewData, setReviewData] = useState(null);

  // Add state for tracking mistakes
  const [mistakes, setMistakes] = useState(0);
  // Add state for accuracy calculation
  const [accuracy, setAccuracy] = useState(100);
  // Add state for tracking attempts
  const [attempt, setAttempt] = useState(1);
  // Add state for API submission status
  const [submissionStatus, setSubmissionStatus] = useState({ 
    submitted: false, 
    success: false, 
    error: null 
  });

  // Add a ref to store the latest review data
  const latestReviewDataRef = useRef(null);

  // Add a new state for analysis result near the other state declarations
  const [analysisResult, setAnalysisResult] = useState(null);
  // Add ref for CompletionPopup setAnalysis function
  const setAnalysisRef = useRef(null);

  // Add isInitializing flag to track modal initialization
  const [isInitializing, setIsInitializing] = useState(true);

  // Function to handle name submission
  const handleNameSubmit = (nameData) => {
    console.log("Name submitted, starting practice:", nameData);
    setUserInfo(nameData);
    setShowNamePopup(false);
    
    // Make sure practice isn't immediately marked as completed
    setCurrentIndex(0);
    setIsPracticeCompleted(false);
    console.log("Practice state after name submission:", { 
      currentIndex, 
      charsLength: characters.length,
      isPracticeCompleted 
    });
  };
  
  // Function to handle attention score updates from FaceTracker
  const handleAttentionChange = (score) => {
    setAttentionScore(score);
  };

  // Function to handle practice completion
  const handlePracticeCompletion = async () => {
    console.log("handlePracticeCompletion called");
    
    try {
      // Use the latest review data from ref to avoid state timing issues
      const currentReviewData = latestReviewDataRef.current || reviewData;
      console.log("Current reviewData in handlePracticeCompletion:", currentReviewData);
      
      // If we have review data, send it to the API before closing
      if (currentReviewData) {
        console.log("Sending review data to API...");
        const result = await sendPracticeReviewData(currentReviewData);
        console.log("API call completed with result:", result);
        
        // Note: We don't close the modal here anymore to allow the user to see the analysis
        // The CompletionPopup component will now handle the closing when the user clicks "Close"
      } else {
        console.log("WARNING: No review data available in either ref or state!");
        // If no review data, still close the modal
    onClose();
      }
    } catch (error) {
      console.error("Error in handlePracticeCompletion:", error);
      // Only close on error if we have no analysis function
      if (!setAnalysisRef.current) {
        onClose();
      } else {
        // If we have an analysis function, update it with the error
        setAnalysisRef.current("An error occurred while processing your request. Please try again.");
      }
    }
  };
  
  // Function to extract question data from practice data
  const extractQuestionData = (practiceData) => {
    if (!practiceData) return null;
    
    let questionData = {
      question: "What did you learn from this typing practice?",
      options: [
        "Improved my typing speed",
        "Learned new keyboard shortcuts",
        "Practiced typing in a different language",
        "All of the above"
      ],
      correctOption: 3, // Default to "All of the above"
      openEndedQuestion: "Please elaborate on what you found most valuable about this typing practice.",
      modelOpenEndedAnswer: "The practice session helped me improve my typing speed and accuracy, while also allowing me to experience typing in a different language. I found the keyboard visualization particularly helpful."
    };
    
    // Check if the practice data has a question field
    if (practiceData.QUESTION) {
      // If QUESTION is an object with QUESTION_TEXT, ANSWER_OPTIONS, and CORRECT_ANSWER
      if (typeof practiceData.QUESTION === 'object' && practiceData.QUESTION.QUESTION_TEXT) {
        const correctOptionIndex = practiceData.QUESTION.CORRECT_ANSWER !== undefined ? 
          parseInt(practiceData.QUESTION.CORRECT_ANSWER, 10) : 0;
          
        console.log("Extracted correct option index:", correctOptionIndex);
        
        questionData = {
          question: practiceData.QUESTION.QUESTION_TEXT,
          options: practiceData.QUESTION.ANSWER_OPTIONS || [],
          correctOption: correctOptionIndex,
          openEndedQuestion: questionData.openEndedQuestion, // Keep default
          modelOpenEndedAnswer: questionData.modelOpenEndedAnswer // Keep default
        };
        
        // Log the correct answer for debugging
        const correctAnswerText = practiceData.QUESTION.ANSWER_OPTIONS && 
          Array.isArray(practiceData.QUESTION.ANSWER_OPTIONS) && 
          correctOptionIndex >= 0 && 
          correctOptionIndex < practiceData.QUESTION.ANSWER_OPTIONS.length ? 
          practiceData.QUESTION.ANSWER_OPTIONS[correctOptionIndex] : 
          "Unknown";
          
        console.log("Correct answer from practice data:", correctAnswerText);
      } else {
        // If QUESTION is a simple string
        const correctOptionIndex = practiceData.CORRECT_OPTION !== undefined ? 
          parseInt(practiceData.CORRECT_OPTION, 10) : 0;
          
        console.log("Extracted correct option index (simple format):", correctOptionIndex);
        
        questionData = {
          question: practiceData.QUESTION,
          options: practiceData.OPTIONS || [],
          correctOption: correctOptionIndex,
          openEndedQuestion: questionData.openEndedQuestion, // Keep default
          modelOpenEndedAnswer: questionData.modelOpenEndedAnswer // Keep default
        };
        
        // Log the correct answer for debugging
        const correctAnswerText = practiceData.OPTIONS && 
          Array.isArray(practiceData.OPTIONS) && 
          correctOptionIndex >= 0 && 
          correctOptionIndex < practiceData.OPTIONS.length ? 
          practiceData.OPTIONS[correctOptionIndex] : 
          "Unknown";
          
        console.log("Correct answer from practice data (simple format):", correctAnswerText);
      }
    }
    
    // Add open-ended question from OPENENDED_QUESTION if available
    if (practiceData.OPENENDED_QUESTION) {
      questionData.openEndedQuestion = practiceData.OPENENDED_QUESTION;
    }
    
    // Add model open-ended answer from MODEL_OPENENDED_ANSWER if available
    if (practiceData.MODEL_OPENENDED_ANSWER) {
      questionData.modelOpenEndedAnswer = practiceData.MODEL_OPENENDED_ANSWER;
    } else if (practiceData.MODEL_ANSWER) {
      // Alternative field name
      questionData.modelOpenEndedAnswer = practiceData.MODEL_ANSWER;
    } else if (practiceData.OPENENDED_MODEL_ANSWER) {
      // Another alternative field name
      questionData.modelOpenEndedAnswer = practiceData.OPENENDED_MODEL_ANSWER;
    } else if (practiceData.QUESTION && 
               typeof practiceData.QUESTION === 'object' && 
               practiceData.QUESTION.MODEL_ANSWER) {
      // Check if it's nested in QUESTION object
      questionData.modelOpenEndedAnswer = practiceData.QUESTION.MODEL_ANSWER;
    }
    
    // Log the extracted question data for debugging
    console.log("Extracted question data:", questionData);
    
    return questionData;
  };

  // Function to load practice data from imported JSON file
  const loadPracticeData = () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get the subdomain from localStorage
      const subDomain = localStorage.getItem('subDomain') || 'virtuprep';
      console.log("Using subdomain:", subDomain);
      
      // Map virtupreplabs to virtuprep if needed
      const normalizedSubdomain = subDomain.toLowerCase() === 'virtupreplabs' ? 'virtuprep' : subDomain.toLowerCase();
      console.log("Normalized subdomain:", normalizedSubdomain);
      
      console.log("Using imported practice data:", practiceData);
      
      // Set the current practice based on normalized subdomain
      if (practiceData[normalizedSubdomain] && practiceData[normalizedSubdomain].length > 0) {
        const currentPracticeData = practiceData[normalizedSubdomain][0];
        console.log("Selected practice data:", currentPracticeData);
        
        // Log all properties for debugging
        console.log("Practice data properties:");
        Object.keys(currentPracticeData).forEach(key => {
          console.log(`${key}:`, currentPracticeData[key]);
        });
        
        setCurrentPractice(currentPracticeData);
        
        // Extract question data
        const extractedQuestionData = extractQuestionData(currentPracticeData);
        setQuestionData(extractedQuestionData);
        
        // Update text and characters with the content from JSON if CONTENT exists
        if (currentPracticeData.CONTENT) {
          console.log("Setting content:", currentPracticeData.CONTENT);
          setText(currentPracticeData.CONTENT);
          setCharacters(currentPracticeData.CONTENT.split(""));
        } else {
          console.warn("No CONTENT field in practice data, using default English text");
          const defaultText = "The quick brown fox jumps over the lazy dog. This is a simple English typing test.";
          setText(defaultText);
          setCharacters(defaultText.split(""));
        }
      } else {
        // If the exact subdomain is not found, try to use virtuprep as fallback
        if (practiceData["virtuprep"] && practiceData["virtuprep"].length > 0) {
          console.log("Subdomain not found, using virtuprep as fallback");
          const fallbackData = practiceData["virtuprep"][0];
          console.log("Fallback practice data:", fallbackData);
          
          // Log all properties of fallback data for debugging
          console.log("Fallback data properties:");
          Object.keys(fallbackData).forEach(key => {
            console.log(`${key}:`, fallbackData[key]);
          });
          
          setCurrentPractice(fallbackData);
          
          // Extract question data
          const extractedQuestionData = extractQuestionData(fallbackData);
          setQuestionData(extractedQuestionData);
          
          // Update text and characters with the content from JSON if CONTENT exists
          if (fallbackData.CONTENT) {
            console.log("Setting fallback content:", fallbackData.CONTENT);
            setText(fallbackData.CONTENT);
            setCharacters(fallbackData.CONTENT.split(""));
          } else {
            console.warn("No CONTENT field in fallback data, using default English text");
            const defaultText = "The quick brown fox jumps over the lazy dog. This is a simple English typing test.";
            setText(defaultText);
            setCharacters(defaultText.split(""));
          }
        } else {
          console.error(`No practice data found for ${normalizedSubdomain} and no fallback available`);
          setError(`No practice data found for ${normalizedSubdomain}`);
          
          // Set default English text as fallback
          const defaultText = "The quick brown fox jumps over the lazy dog. This is a simple English typing test.";
          setText(defaultText);
          setCharacters(defaultText.split(""));
          
          // Set default question data
          setQuestionData({
            question: "What did you learn from this typing practice?",
            options: [
              "Improved my typing speed",
              "Learned new keyboard shortcuts",
              "Practiced typing in a different language",
              "All of the above"
            ],
            correctOption: 3,
            modelOpenEndedAnswer: "The practice session helped me improve my typing speed and accuracy, while also allowing me to experience typing in a different language. I found the keyboard visualization particularly helpful."
          });
        }
      }
      
      setLoading(false);
    } catch (err) {
      console.error("Error loading practice data:", err);
      setError(`Failed to load practice data: ${err.message}`);
      setLoading(false);
      
      // Set default English text as fallback
      const defaultText = "The quick brown fox jumps over the lazy dog. This is a simple English typing test.";
      setText(defaultText);
      setCharacters(defaultText.split(""));
      
      // Set default question data
      setQuestionData({
        question: "What did you learn from this typing practice?",
        options: [
          "Improved my typing speed",
          "Learned new keyboard shortcuts",
          "Practiced typing in a different language",
          "All of the above"
        ],
        correctOption: 3,
        modelOpenEndedAnswer: "The practice session helped me improve my typing speed and accuracy, while also allowing me to experience typing in a different language. I found the keyboard visualization particularly helpful."
      });
    }
  };
  
  // Add a resetPracticeState function to completely reset the practice
  const resetPracticeState = useCallback(() => {
    console.log("Resetting practice state completely");
    
    // Reset typing progress
    setCurrentIndex(0);
    setIsTypingStarted(false);
    setStartTime(null);
    
    // Reset completion and review status
    setIsPracticeCompleted(false);
    setReviewData(null);
    latestReviewDataRef.current = null;
    
    // Reset user response
    setUserResponse(null);
    setResponseCorrect(null);
    
    // Reset statistics
    setTypingSpeed({ wpm: 0, cpm: 0 });
    setElapsedTime(0);
    setWordsTyped(0);
    setAccuracy(100);
    setMistakes(0);
    
    // Reset analysis
    setAnalysisResult(null);
    if (setAnalysisRef.current) {
      setAnalysisRef.current = null;
    }
    
    // Reset submission status
    setSubmissionStatus({ 
      submitted: false, 
      success: false, 
      error: null 
    });
    
  }, []);

  // Update the useEffect to properly reset when reopening
  useEffect(() => {
    if (isOpen) {
      console.log("Practice modal opened, resetting state");
      setIsInitializing(true);
      resetPracticeState();
      setShowNamePopup(true);
      loadPracticeData();
      
      // After a short delay, mark initialization as complete
      const timer = setTimeout(() => {
        setIsInitializing(false);
        console.log("Practice initialization complete");
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [isOpen, resetPracticeState]);

  // Add the initialization check to the completion effect
  useEffect(() => {
    console.log(`Checking completion: chars length=${characters.length}, currentIndex=${currentIndex}, isInitializing=${isInitializing}`);
    if (!isInitializing && characters.length > 0 && currentIndex === characters.length && currentIndex > 0) {
      console.log("Practice completed!");
      setIsPracticeCompleted(true);
    }
  }, [currentIndex, characters, isInitializing]);
  
  const keySuggestions = {
    // Hebrew characters mapped to English keyboard keys
    "א": "T",      // Alef
    "ב": "C",      // Bet
    "ג": "D",      // Gimel
    "ד": "S",      // Dalet
    "ה": "V",      // He
    "ו": "T",      // Vav
    "ז": "Z",      // Zayin
    "ח": "J",      // Het
    "ט": "Y",      // Tet
    "י": "H",      // Yod
    "כ": "F",      // Kaf
    "ל": "K",      // Lamed
    "מ": "N",      // Mem
    "נ": "B",      // Nun
    "ס": "X",      // Samekh
    "ע": "G",      // Ayin
    "פ": "P",      // Pe
    "צ": "M",      // Tsadi
    "ק": "E",      // Qof
    "ר": "R",      // Resh
    "ש": "A",      // Shin
    "ת": ",",      // Tav
    "ם": "O",      // Final Mem
    "ן": "I",      // Final Nun
    "ץ": ".",      // Final Tsadi
    "ף": ";",      // Final Pe
    "ך": "L",      // Final Kaf
    "׃": ";",      // Sof Pasuq
    "ׄ": "Shift + N",  // Upper Dot
    "ׅ": "Shift + ]",  // Lower Dot
    "ׂ": "Shift + Q",  // Sin Dot
    "ׁ": "Shift + W",  // Shin Dot
    "ֿ": "Shift + [",  // Rafe
    "־": "-",      // Maqaf
    "ֽ": "Shift + 7",  // Meteg
    "ֻ": "Shift + \\", // Qubuts
    "ֹ": "Shift + U",  // Holam
    "ַ": "Shift + P",  // Patah
    "ּ": "Shift + S",  // Dagesh
    "ֵ": "Shift + M",  // Tsere
    "ֱ": "Shift + C",  // Hataf Segol
    "ֶ": "Shift + X",  // Segol
    "ְ": "Shift + A",  // Sheva
    "ױ": "Shift + G",  // Vav Yod
    "װ": "Shift + Y",  // Double Vav
    "ֳ": "Shift + R",  // Hataf Qamats
    "ָ": "Shift + E",  // Qamats
    "ִ": "Shift + J",  // Hiriq
    "?": "Shift + /",  // Question Mark
    "!": "Shift + 1",  // Exclamation Mark
    ",": ",",      // Comma
    ".": ".",      // Period
    " ": "Space",  // Space
  };

  // Memoize getSuggestion to prevent it from being recreated on every render
  const getSuggestion = useCallback((char) => {
    const typingClue = document.getElementById("typingClue");
    if (typingClue) {
      // Get the suggestion for the current character
      const suggestion = keySuggestions[char];
      if (suggestion) {
        typingClue.textContent = suggestion;
      } else {
        // If no special suggestion, show the character itself
        typingClue.textContent = char;
      }
    }
  }, [/* keySuggestions is static, so no dependencies needed */]);

  const detectLanguageAndSetSuggestion = useCallback(() => {
    console.log("Detecting language for text:", text);
    
    const englishRegex = /[A-Za-z0-9.,!?'"()\-]/;
    // Check if text contains Hebrew characters
    const hebrewRegex = /[\u0590-\u05FF]/;
    // Check if text contains Arabic characters
    const arabicRegex = /[\u0600-\u06FF]/;
    // Check if text contains Hindi characters
    const hindiRegex = /[\u0900-\u097F]/;
    // Check if text contains Spanish characters
    const spanishRegex = /[áéíóúñ]/;

    if (hebrewRegex.test(text)) {
      console.log("Detected Hebrew text");
      setSelectedLanguage("Hebrew");
    } else if (arabicRegex.test(text)) {
      console.log("Detected Arabic text");
      setSelectedLanguage("Arabic");
    } else if (hindiRegex.test(text)) {
      console.log("Detected Hindi text");
      setSelectedLanguage("Hindi");
    } else if (spanishRegex.test(text)) {
      console.log("Detected Spanish text");
      setSelectedLanguage("Spanish");
    } else if (englishRegex.test(text)) {
      console.log("Detected English text");
      setSelectedLanguage("English");
    } else {
      console.log("No specific language detected, defaulting to English");
      setSelectedLanguage("English");
    }

    // Set initial suggestion for the first character
    if (characters.length > 0) {
      getSuggestion(characters[0]);
    }
  }, [text, characters, getSuggestion, setSelectedLanguage]);

  // Call detectLanguageAndSetSuggestion only when text actually changes, not when the function reference changes
  useEffect(() => {
    detectLanguageAndSetSuggestion();
  }, [text]); // Remove detectLanguageAndSetSuggestion from dependencies

  // Function to calculate typing speed
  const calculateTypingSpeed = () => {
    if (!startTime || !isTypingStarted) return;
    
    const currentTime = new Date().getTime();
    const timeElapsed = (currentTime - startTime) / 1000; // Convert to seconds
    const minutesElapsed = timeElapsed / 60;
    
    // Calculate CPM (Characters Per Minute)
    const cpm = Math.round((currentIndex / minutesElapsed));
    
    // Calculate WPM (Words Per Minute)
    // Assuming average word length of 5 characters
    const wpm = Math.round((currentIndex / 5) / minutesElapsed);
    
    setTypingSpeed({ wpm, cpm });
  };

  // Update typing speed every second
  useEffect(() => {
    let interval;
    if (isTypingStarted) {
      interval = setInterval(calculateTypingSpeed, 1000);
    }
    return () => clearInterval(interval);
  }, [startTime, isTypingStarted, currentIndex]);

  // Function to calculate elapsed time
  const calculateElapsedTime = () => {
    if (!startTime || !isTypingStarted) return;
    
    const currentTime = new Date().getTime();
    const timeElapsed = Math.floor((currentTime - startTime) / 1000); // Convert to seconds
    setElapsedTime(timeElapsed);
  };

  // Update elapsed time every second
  useEffect(() => {
    let interval;
    if (isTypingStarted) {
      interval = setInterval(calculateElapsedTime, 1000);
    }
    return () => clearInterval(interval);
  }, [startTime, isTypingStarted]);

  // Function to calculate words typed - wrapped in useCallback
  const calculateWordsTyped = useCallback(() => {
    if (currentIndex === 0) return 0;
    
    // Count words by splitting the text up to currentIndex
    const textSoFar = characters.slice(0, currentIndex).join('');
    const words = textSoFar.split(/\s+/).filter(word => word.length > 0);
    setWordsTyped(words.length);
  }, [currentIndex, characters]);

  // Update words typed when currentIndex changes
  useEffect(() => {
    calculateWordsTyped();
  }, [calculateWordsTyped]); // Now this is fine since calculateWordsTyped has the dependencies

  // Reset stats when modal is opened
  useEffect(() => {
    if (isOpen) {
      setStartTime(null);
      setIsTypingStarted(false);
      setTypingSpeed({ wpm: 0, cpm: 0 });
      setElapsedTime(0);
      setWordsTyped(0);
    }
  }, [isOpen]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    // Add null check
    if (seconds === undefined || seconds === null) {
      return '0:00';
    }
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // Add null check before toString
    return `${minutes}:${(remainingSeconds !== undefined ? remainingSeconds.toString() : '0').padStart(2, '0')}`;
  };

  // Function to send practice review data to API
  const sendPracticeReviewData = async (reviewData) => {
    try {
      console.log("sendPracticeReviewData function called with:", reviewData);
      
      // Clear previous submission status
      setSubmissionStatus({ submitted: false, success: false, error: null });
      
      // Get the subdomain (school identifier)
      const subDomain = localStorage.getItem('subDomain') || 'virtuprep';

      // Safely get values or provide defaults to avoid undefined errors
      const safeReviewRating = reviewData?.rating !== undefined ? reviewData.rating : 5;
      const safeComprehension = reviewData?.comprehension !== undefined ? reviewData.comprehension : 100;
      
      // Explicitly extract the openEndedAnswer from reviewData
      const safeOpenEndedAnswer = reviewData?.openEndedAnswer || '';
      console.log("User's open-ended answer:", safeOpenEndedAnswer);
      
      const safeReviewComment = reviewData?.comment || '';
      
      // Performance metrics with safety checks
      const safeTypingSpeed = typingSpeed?.wpm !== undefined ? typingSpeed.wpm : 0;
      const safeAccuracy = accuracy !== undefined ? accuracy : 100;
      const safeWordTyped = wordsTyped !== undefined ? wordsTyped : 0;
      const safeElapsedTime = elapsedTime !== undefined ? elapsedTime : 0;
      const safeMistakes = mistakes !== undefined ? mistakes : 0;
      const safeAttentionScore = attentionScore !== undefined ? attentionScore : 100;
      
      // Safely calculate performance
      const safePerformance = Math.round((safeTypingSpeed * safeAccuracy) / 100);
      
      // Safely get model open ended answer
      const safeModelOpenEndedAnswer = questionData?.modelOpenEndedAnswer || 
        "The practice session helps improve typing speed and accuracy while gaining experience with different languages. The keyboard visualization is particularly helpful for learning proper finger placement.";
      
      // Log the content and reference fields for verification
      console.log("Practice data text content:", text);
      console.log("Practice data REFERENCE:", currentPractice?.REFERENCE);
      
      // Get reference content using text as fallback
      const referenceContent = currentPractice?.REFERENCE || text || "";
      console.log("Using reference content:", referenceContent);
      
      // Safely get the correct answer for the MCQ question
      let correctAnswerText = "";
      if (questionData && questionData.options && Array.isArray(questionData.options) && questionData.correctOption !== undefined) {
        const correctOptionIndex = questionData.correctOption;
        if (correctOptionIndex >= 0 && correctOptionIndex < questionData.options.length) {
          correctAnswerText = questionData.options[correctOptionIndex];
        }
      }
      
      // If we couldn't get the correct answer, use a default
      if (!correctAnswerText) {
        correctAnswerText = "All of the above";
      }
      
      console.log("Correct MCQ answer:", correctAnswerText);
      
      // Get blockTitle and use it for courseTitle as well
      const blockTitle = currentPractice?.TILE || "Mastering Key Words for Complex Sentences";

      // Create a comprehensive data object with all required fields
      const practiceReviewData = {
        // User info
        firstName: userInfo?.firstName || '',
        lastName: userInfo?.lastName || '',
        
        // Practice context
        blockTitle: blockTitle,
        practiceSerialNumber: currentPractice?.SERIAL_NUMBER || "001",
        homeLanguage: selectedLanguage || "English",
        rating: safeReviewRating,
        courseTitle: blockTitle, // Use the same value as blockTitle
        attempt: attempt || 1,
        
        // Performance metrics
        totalTime: safeElapsedTime,
        words: safeWordTyped,
        speed: safeTypingSpeed,
        performance: safePerformance,
        accuracy: safeAccuracy,
        comprehension: safeComprehension,
        focus: safeAttentionScore,
        mistakes: safeMistakes,
        
        // Content data
        audioSummary: currentPractice?.AUDIO_SUMMARY || "",
        material: "", // Set material to be empty as requested
        videoSummary: currentPractice?.VIDEO_SUMMARY || "",
        reference: referenceContent, // Use the variable we created above
        
        // Question and answer data
        correctAnswer: correctAnswerText, // Use the correct answer we determined above
        correctExplanation: questionData?.explanation || "",
        openEndedQue: questionData?.openEndedQuestion || "Please share your thoughts about this practice session.",
        openEndedAnsByUser: safeOpenEndedAnswer, // This contains the user's typed open-ended answer
        modelOpenEndedAnswer: safeModelOpenEndedAnswer,
        
        // School identifier
        school: subDomain,
        
        // Additional review data
        reviewComment: safeReviewComment
      };
      
      // Log the openEndedAnsByUser value for verification
      console.log("openEndedAnsByUser being sent to API:", practiceReviewData.openEndedAnsByUser);
      console.log("Sending practice review data:", practiceReviewData);
      
      // Update API endpoint URL to the production URL
      const apiUrl = 'https://membership.virtupreplabs.com/api/public/school/getPracticeReview';
      console.log("Sending API request to:", apiUrl);
      
      try {
        // Use GET request with parameters in 'params' option
        const response = await axios.get(apiUrl, {
          params: practiceReviewData
        });    
        console.log("API response received:", response.data);
        
        // Safely check response properties
        if (response?.data?.success) {
          console.log("API call successful");
          setSubmissionStatus({ submitted: true, success: true, error: null });
          
          // If we have analysis data in the response, update our state
          if (response.data.aiAnalysis) {
            console.log("AI analysis received:", response.data.aiAnalysis);
            
            // Extract suggestions
            const extractedSuggestions = extractSuggestions(response.data.aiAnalysis.content);
            console.log("Extracted suggestions:", extractedSuggestions);
            
            // Store the analysis result in our state
            setAnalysisResult(response.data.aiAnalysis.content);
            
            // If we have a ref to the setAnalysis function, call it with formatted suggestions
            if (setAnalysisRef.current) {
              console.log("Updating analysis in CompletionPopup");
              
              // Format the suggestions as an array of sections
              if (extractedSuggestions) {
                // Create an array of suggestion sections
                const suggestionsArray = [
                  {
                    title: "Strengthen Your Understanding",
                    content: extractedSuggestions.understand || "No specific understanding suggestions available."
                  },
                  {
                    title: "Enhance Your Efficiency",
                    content: extractedSuggestions.efficiency || "No specific efficiency suggestions available."
                  },
                  {
                    title: "Strengthen Your Skills",
                    content: extractedSuggestions.skills || "No specific skills suggestions available."
                  }
                ];
                
                // Add spent time as a prefix to the first section
                if (extractedSuggestions.spent) {
                  suggestionsArray[0].prefix = extractedSuggestions.spent;
                }
                
                // Pass the array to the CompletionPopup
                setAnalysisRef.current(suggestionsArray);
              } else {
                // If extraction failed, show the full analysis
                setAnalysisRef.current(response.data.aiAnalysis.content);
              }
            }
          }
        } else {
          console.log("API call failed with message:", response?.data?.message);
          setSubmissionStatus({ 
            submitted: true, 
            success: false, 
            error: response?.data?.message || "Failed to submit review data" 
          });
          
          // Update analysis with error message
          const errorMessage = "Analysis could not be generated. Please try again later.";
          setAnalysisResult(errorMessage);
          
          // If we have a ref to the setAnalysis function, call it with the error
          if (setAnalysisRef.current) {
            setAnalysisRef.current(errorMessage);
          }
        }
        
        return response?.data || { success: false };
      } catch (apiError) {
        console.error("API call threw error:", apiError);
        
        // Check specifically for URL too long errors that can happen with GET requests
        const isUrlTooLongError = apiError.message && (
          apiError.message.includes("URI too long") || 
          apiError.message.includes("414") || 
          apiError.message.includes("413")
        );
        
        let errorMessage = "An error occurred while analyzing your practice session";
        
        if (isUrlTooLongError) {
          errorMessage = "Too much data to send in GET request. Try shortening your answers or contact support.";
        } else {
          errorMessage += ": " + (apiError?.message || "Unknown error");
        }
        
        setSubmissionStatus({ 
          submitted: true, 
          success: false, 
          error: errorMessage
        });
        
        // Update analysis with error message
        setAnalysisResult(errorMessage);
        
        // If we have a ref to the setAnalysis function, call it with the error
        if (setAnalysisRef.current) {
          setAnalysisRef.current(errorMessage);
        }
        
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Error submitting practice review:", error);
      setSubmissionStatus({ 
        submitted: true, 
        success: false, 
        error: error?.message || "An error occurred while submitting review data" 
      });
      
      // Update analysis with error message
      const errorMessage = "An error occurred while preparing your practice analysis: " + 
        (error?.message || "Unknown error");
      setAnalysisResult(errorMessage);
      
      // If we have a ref to the setAnalysis function, call it with the error
      if (setAnalysisRef.current) {
        setAnalysisRef.current(errorMessage);
      }
      
      return { success: false, message: error?.message || "Unknown error" };
    }
  };

  // Add this function after the sendPracticeReviewData function
  const extractSuggestions = (analysisText) => {
    if (!analysisText) return null;
    
    try {
      // Find the STUDENT SUGGESTIONS section
      const suggestionsMatch = analysisText.match(/{{STUDENT SUGGESTIONS}}:([^-]*)/s);
      if (!suggestionsMatch || !suggestionsMatch[1]) return null;
      
      const suggestionsText = suggestionsMatch[1].trim();
      
      // Create an object to store our extracted suggestions
      const suggestions = {
        spent: "",
        handwrite: "",
        understand: "",
        efficiency: "",
        skills: "",
        adjustment: ""
      };
      
      // Extract time spent
      const spentMatch = suggestionsText.match(/• (.*?), you spent approximately (.*?) on this module/);
      if (spentMatch) {
        suggestions.spent = `You spent approximately ${spentMatch[2]} on this module.`;
      }
      
      // Extract Handwrite This Key Concept
      const handwriteMatch = suggestionsText.match(/{{Handwrite This Key Concept}}:(.*?)(?={{|$)/s);
      if (handwriteMatch) {
        suggestions.handwrite = handwriteMatch[1].trim();
      }
      
      // Extract Strengthen Your Understanding
      const understandMatch = suggestionsText.match(/{{Strengthen Your Understanding}}:(.*?)(?={{|$)/s);
      if (understandMatch) {
        suggestions.understand = understandMatch[1].trim();
      }
      
      // Extract Enhance Your Efficiency
      const efficiencyMatch = suggestionsText.match(/{{Enhance Your Efficiency}}:(.*?)(?={{|$)/s);
      if (efficiencyMatch) {
        suggestions.efficiency = efficiencyMatch[1].trim();
      }
      
      // Extract Strengthen Your Skills
      const skillsMatch = suggestionsText.match(/{{Strengthen Your Skills}}:(.*?)(?={{|$)/s);
      if (skillsMatch) {
        suggestions.skills = skillsMatch[1].trim();
      }
      
      // Extract Mastery Path Adjustment
      const adjustmentMatch = suggestionsText.match(/{{Mastery Path Adjustment}}:(.*?)(?={{|$)/s);
      if (adjustmentMatch) {
        suggestions.adjustment = adjustmentMatch[1].trim();
      }
      
      // Clean up extracted text by removing bullet points and unnecessary whitespace
      Object.keys(suggestions).forEach(key => {
        if (suggestions[key]) {
          // Remove bullet points and clean up whitespace
          suggestions[key] = suggestions[key].replace(/•\s*/g, '').trim();
          // Remove excessive newlines and replace with a single space
          suggestions[key] = suggestions[key].replace(/\n+/g, ' ').trim();
        }
      });
      
      return suggestions;
    } catch (error) {
      console.error("Error extracting suggestions:", error);
      return null;
    }
  };

  // Update handleKeyPress to also mark initialization as complete on first keypress
  const handleKeyPress = useCallback((e) => {
    // If practice is completed, don't process keypresses
    if (isPracticeCompleted) return;
    
    // Ignore Control key and any key combinations with Control key
    if (e.key === "Control" || e.ctrlKey) {
      console.log("Control key or Control combination detected - ignoring");
      return;
    }
    
    if (e.key === " ") {
      e.preventDefault();
    }
    console.log(`Key pressed: ${e.key}, Key code: ${e.which}`);
    
    // Ensure initialization is complete when user starts typing
    if (isInitializing) {
      setIsInitializing(false);
    }
    
    // Start timing when first key is pressed
    if (!isTypingStarted) {
      setIsTypingStarted(true);
      setStartTime(new Date().getTime());
    }

    // Get the current character we're trying to type
    const currentChar = characters[currentIndex];
    
    // Handle key press based on selected language
    let isKeyCorrect = false;
    
    if (selectedLanguage === "Hebrew") {
      // For Hebrew, check against keySuggestions mapping
      const expectedKey = keySuggestions[currentChar];
      
      // Debug log to help diagnose the issue
      console.log(`Current char: "${currentChar}", Expected key: "${expectedKey}"`);
      
      // Only proceed if we have a valid expected key
      if (expectedKey) {
        const isShiftCombination = expectedKey.startsWith('Shift + ');
        const expectedNormalKey = isShiftCombination ? expectedKey.split(' + ')[1] : expectedKey;
        
        // Convert expected key to its key code
        let expectedKeyCode;
        if (expectedNormalKey === 'Space') {
          expectedKeyCode = 32; // Space key code
        } else if (expectedNormalKey && expectedNormalKey.length > 0) {
          expectedKeyCode = expectedNormalKey.charCodeAt(0);
          // Handle special cases for punctuation
          if (expectedNormalKey === ',') expectedKeyCode = 188;
          if (expectedNormalKey === '.') expectedKeyCode = 190;
          if (expectedNormalKey === '/') expectedKeyCode = 191;
          if (expectedNormalKey === ';') expectedKeyCode = 59;
          if (expectedNormalKey === "'") expectedKeyCode = 222;
          if (expectedNormalKey === '[') expectedKeyCode = 219;
          if (expectedNormalKey === ']') expectedKeyCode = 221;
          if (expectedNormalKey === '\\') expectedKeyCode = 220;
          if (expectedNormalKey === '-') expectedKeyCode = 173;
          if (expectedNormalKey === '=') expectedKeyCode = 61;
          if (expectedNormalKey === '`') expectedKeyCode = 192;
        } else {
          // If we don't have a valid key to check against, default to impossible keycode
          console.warn(`No valid key mapping for character: ${currentChar}`);
          expectedKeyCode = -1;
        }
        
        isKeyCorrect = e.which === expectedKeyCode;
      } else {
        // If the character isn't in our mapping, we'll check for direct match
        console.warn(`No mapping found for Hebrew character: ${currentChar}`);
        isKeyCorrect = e.key === currentChar;
      }
    } else if (selectedLanguage === "Hindi") {
      // For Hindi, check if the pressed key matches the character
      isKeyCorrect = e.key === currentChar;
    } else if (selectedLanguage === "Arabic") {
      // For Arabic, check if the pressed key matches the character
      isKeyCorrect = e.key === currentChar;
    } else if (selectedLanguage === "Spanish") {
      // For Spanish, check if the pressed key matches the character
      isKeyCorrect = e.key === currentChar;
    } else if (selectedLanguage === "English") {
      // For English, check if the pressed key matches the character
      isKeyCorrect = e.key === currentChar;
    }

    // Safely set pressed key with null check
    const keyCode = e.which !== undefined ? e.which.toString() : '';
    setPressedKey(keyCode);

    if (currentIndex < characters.length) {
      if (isKeyCorrect) {
        setIsCorrect(true); // Correct key pressed
        setCurrentIndex((prevIndex) => prevIndex + 1);
        // Get suggestion for next character
        if (currentIndex + 1 < characters.length) {
          getSuggestion(characters[currentIndex + 1]);
        }
      } else {
        setIsCorrect(false); // Wrong key pressed
        // Increment mistake count
        setMistakes(prevMistakes => prevMistakes + 1);
      }
    }

    // Calculate accuracy based on mistakes and total keys pressed
    const totalKeysPressed = currentIndex + (isKeyCorrect ? 1 : 0);
    if (totalKeysPressed > 0) {
      const accuracyValue = Math.max(0, Math.min(100, Math.round(((totalKeysPressed - mistakes) / totalKeysPressed) * 100)));
      setAccuracy(accuracyValue);
    }

    // Remove highlight after 300ms
    setTimeout(() => {
      setPressedKey(null);
      setIsCorrect(null);
    }, 300);
  }, [characters, currentIndex, getSuggestion, isPracticeCompleted, isTypingStarted, keySuggestions, mistakes, selectedLanguage, isInitializing]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // Add useEffect to handle body scrolling when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      // Save current scroll position
      const scrollY = window.scrollY;
      
      // Apply multiple properties to prevent scrolling
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden'; // html element
      document.documentElement.style.overscrollBehavior = 'none';
    } else {
      // Restore scrolling and position
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      document.body.style.overscrollBehavior = '';
      document.documentElement.style.overflow = '';
      document.documentElement.style.overscrollBehavior = '';
      
      // Restore scroll position
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
    
    // Cleanup function to ensure scrolling is re-enabled when component unmounts
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      document.body.style.overscrollBehavior = '';
      document.documentElement.style.overflow = '';
      document.documentElement.style.overscrollBehavior = '';
    };
  }, [isOpen]);

  // Derive the current character's key suggestion
  const currentChar = characters[currentIndex] || '';
  const keyToPress = keySuggestions[currentChar] || currentChar;

  // Update handleQuestionResponse to include all necessary data
  const handleQuestionResponse = (selectedOptionIndex, openEndedAnswer, reviewData) => {
    console.log("handleQuestionResponse called with:", {
      selectedOptionIndex,
      openEndedAnswer,
      reviewData
    });
    
    setUserResponse(selectedOptionIndex);
    
    // Log the open-ended answer received from CompletionPopup
    console.log("Open-ended answer received from user:", openEndedAnswer);
    
    // Store the setAnalysis function from CompletionPopup if it exists
    if (reviewData && reviewData.setAnalysis) {
      console.log("Storing CompletionPopup's setAnalysis function");
      setAnalysisRef.current = reviewData.setAnalysis;
    }
    
    // Create a comprehensive review data object
    const completeReviewData = {
      ...reviewData,
      openEndedAnswer: openEndedAnswer || '',
      selectedOptionIndex,
      accuracy,
      mistakes,
      comprehension: reviewData?.comprehension || 80, // Default comprehension value if not provided
    };
    
    // Save review data in both state and ref for immediate access
    setReviewData(completeReviewData);
    latestReviewDataRef.current = completeReviewData;
    console.log("Review data set in state and ref:", completeReviewData);
    
    // Check if the selected option is correct
    if (questionData && questionData.correctOption !== undefined) {
      const isCorrect = selectedOptionIndex === questionData.correctOption;
      setResponseCorrect(isCorrect);
      
      // Get the text of the selected option and correct option
      const selectedOptionText = questionData.options?.[selectedOptionIndex] || 'Unknown option';
      const correctOptionText = questionData.options?.[questionData.correctOption] || 'Unknown correct option';
      
      console.log("Selected option:", selectedOptionIndex, selectedOptionText);
      console.log("Correct option:", questionData.correctOption, correctOptionText);
      
      console.log("Question response:", {
        userFirstName: userInfo?.firstName || '',
        userLastName: userInfo?.lastName || '',
        question: questionData.question,
        selectedOption: selectedOptionText,
        correctOption: correctOptionText,
        isCorrect,
        openEndedQuestion: questionData.openEndedQuestion || '',
        openEndedAnswer: openEndedAnswer || 'No answer provided',
        modelOpenEndedAnswer: questionData.modelOpenEndedAnswer || 'No model answer provided',
        reviewRating: reviewData?.rating,
        reviewComment: reviewData?.comment,
        accuracy,
        mistakes,
        attentionScore,
        wpm: typingSpeed?.wpm || 0
      });
    }
  };

  // When text changes, reset typing progress for the specific text
  useEffect(() => {
    console.log("Text changed, resetting characters and typing progress");
    setCharacters(text.split(""));
    setCurrentIndex(0);
    setIsTypingStarted(false);
    setStartTime(null);
    setIsPracticeCompleted(false);
  }, [text]);

  if (!isOpen) return null;

  return (
    <div 
      className="modal-overlay position-fixed top-0 start-0 d-flex justify-content-center align-items-center" 
      onClick={onClose} 
      style={{ width: "100vw", height: "100vh", background: "rgba(0, 0, 0, 0.5)", overflow: "hidden", zIndex: "1000001" }}>
      <div 
        className="modal-content practice-modal-content" 
        onClick={(e) => e.stopPropagation()} 
        style={{ 
          width: "90%", 
          maxWidth: "1600px", 
          height: "90vh", 
          maxHeight: "900px", 
          background: "white", 
          overflow: "hidden", 
          position: "relative" 
        }}>
        
        {/* Show name collection popup */}
        {showNamePopup && (
          <NameCollectionPopup onSubmit={handleNameSubmit} />
        )}
        
        {/* Render CompletionPopup component when practice is completed */}
        {isPracticeCompleted && (
          <CompletionPopup 
            typingSpeed={typingSpeed}
            elapsedTime={elapsedTime}
            attentionScore={attentionScore}
            questionData={questionData}
            onComplete={handleQuestionResponse}
            onNext={handlePracticeCompletion}
            closeModal={onClose}
            userInfo={userInfo}
          />
        )}
        
        {/* Show submission status toast notification */}
        {submissionStatus.submitted && (
          <div 
            className={`position-absolute d-flex align-items-center p-3 ${submissionStatus.success ? 'bg-success' : 'bg-danger'}`}
            style={{
              top: '10px',
              right: '10px',
              zIndex: 9999,
              borderRadius: '5px',
              maxWidth: '300px',
              color: 'white',
              fontFamily: 'Montserrat-large'
            }}
          >
            <div className="me-2">
              {submissionStatus.success ? 
                <i className="fas fa-check-circle"></i> : 
                <i className="fas fa-exclamation-circle"></i>
              }
            </div>
            <div>
              {submissionStatus.success ? 
                'Practice data submitted successfully!' : 
                `Error: ${submissionStatus.error}`
              }
            </div>
          </div>
        )}
        
        <div className="container-fluid h-100">
          
          {/* Add loading/error indicator */}
          
          <div className="row p-2 rounded-top" 
          style={{ background: "black", height:'5%', position: 'relative' }}>
            <div className="col text-center text-white">
              {/* Display the title from JSON data */}
              <p className="m-0">{currentPractice?.TILE || "Mastering Key Words for Complex Sentences"}</p>
              {/* Add close button */}
              <button 
                className="btn btn-sm position-absolute" 
                style={{ 
                  top: '50%', 
                  right: '15px', 
                  transform: 'translateY(-50%)', 
                  background: 'transparent',
                  color: 'white',
                  fontSize: '1.5rem',
                  padding: '0',
                  lineHeight: '1'
                }} 
                onClick={onClose}
                aria-label="Close"
              >
                ×
              </button>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center" 
          style={{ height: "45%" }}>
            {/* Left side divs */}
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
                {/* Use the FaceTracker component */}
                <FaceTracker 
                  isActive={isOpen && !isPracticeCompleted} 
                  onAttentionChange={handleAttentionChange} 
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
                {/* This area can be used for additional controls or information */}
              </div>
            </div>

            {/* Center content div */}
            <div className="col-md-4 col-sm-6 p-2 h-100 rounded-top">
              <div className="content-div h-100 p-2 bg-black overflow-auto">
                <p className="montser-lrg text-left">
                  {characters.map((char, index) => (
                    <span key={index} className={index === currentIndex ? "currentTypo" : index === currentIndex + 1 ? "nextTypo" : ""}>
                      {char}
                    </span>
                  ))}
                </p>
              </div>
            </div>

            {/* Right side divs */}
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top d-none d-md-block">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
               
              </div>
            </div>
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
                <ul className="p-2" style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%' }}>
                  <li>
                    <p className="d-flex">
                      <span className="montser-mdm" style={{ width: '80px' }}>Time</span>
                      <span className="montser-mdm" id="time">{formatTime(elapsedTime)}</span>
                    </p>  
                  </li>
                  <li>
                    <p className="d-flex">
                      <span className="montser-mdm" style={{ width: '80px' }}>Speed</span>
                      <span className="montser-mdm" id="speed">{typingSpeed.wpm} wpm</span>
                    </p>  
                  </li>
                  <li>
                    <p className="d-flex">
                      <span className="montser-mdm" style={{ width: '80px' }}>Words</span>
                      <span className="montser-mdm" id="totalWords">{wordsTyped}</span>
                    </p>  
                  </li>
                  <li>
                    <p className="d-flex">
                      <span className="montser-mdm" style={{ width: '80px' }}>Focus</span>
                      <span className="montser-mdm" id="focus">{attentionScore}%</span>
                    </p>  
                  </li>
                  <li>
                    <p className="d-flex">
                      <span className="montser-mdm" style={{ width: '80px' }}>Accuracy</span>
                      <span className="montser-mdm" id="Accuracy"> </span>
                    </p>  
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Keyboard area */}
          <div className="row p-2 rounded-top" style={{ height: "45%", background: "black" }}>
            {/* Left side div */}
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top d-none d-md-block">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
          
              </div>
            </div>

            {/* Keyboard */}
            <div className="col-md-8 col-sm-9">
              <KeyboardManager 
                language={selectedLanguage} 
                onLanguageChange={setSelectedLanguage} 
                pressedKey={pressedKey} 
                isCorrect={isCorrect} 
              />
            </div>

            {/* Right side div */}
            <div className="col-md-2 col-sm-3 p-2 h-100 rounded-top">
              <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-black">
                <p className="text-white"><span className="montser-lrg">Type</span></p>
                <p className="text-white">
                  <span id="typingClue" className="montser-lrg d-inline-block bg-dark p-2 rounded" style={{ minWidth: "40px", textAlign: "center" }}>
                    {keyToPress}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PracticeModal;
