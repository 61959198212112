// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
  Global font override
  This file overrides all font-family definitions in the application
  to use the new font stack
*/

/* Override all elements to use the new font stack */
* {
  font-family: var(--font-fk-grotesk-neue) !important;
}

/* Override specific font classes from the existing codebase */
.montser-lrg,
.montser-mdm,
.montser-sml,
.montser-lrg-heading-sml,
.montser-MediumItalic,
.montser-ThinItalic,
.montser-LightItalic,
.montser-sml-LightItalic,
.montser-sml-LightItalic-1rem,
.montser-lrg-LightItalic,
.monster-lrg-italic-normal,
.monster-mdm-italic-normal,
.montser-sml-italic,
.montser-sml-bold,
.montser-lrg-bold-re,
.montser-lrg-bold-0-8rem,
.monster-lrg-normal,
.montser-mdm-2,
.montser-mdm-1rem,
.montser-mdm-italic,
.montser-mdm,
.montser-sml,
.montser-sml-italic,
.montser-sml-bold,
.montser-lrg-bold,
.monster-lrg-normal,
.monster-lrg-italic,
.text-virtu-header,
.custom-large-heading,
.custom-extra-large-heading,
.reg-montser-lrg {
  font-family: var(--font-fk-grotesk-neue) !important;
  /* Keep other styling like weight, size, style */
}

/* Override Bootstrap and other framework fonts */
body,
button,
input,
select,
textarea,
.nav-link,
.navbar-brand,
.btn,
.dropdown-item,
h1, h2, h3, h4, h5, h6,
p, span, div, a {
  font-family: var(--font-fk-grotesk-neue) !important;
}

/* Replace the --sans variable */
:root {
  --sans: var(--font-fk-grotesk-neue);
} `, "",{"version":3,"sources":["webpack://./src/font-override.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED,oDAAoD;AACpD;EACE,mDAAmD;AACrD;;AAEA,8DAA8D;AAC9D;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EA+BE,mDAAmD;EACnD,gDAAgD;AAClD;;AAEA,iDAAiD;AACjD;;;;;;;;;;;EAWE,mDAAmD;AACrD;;AAEA,gCAAgC;AAChC;EACE,mCAAmC;AACrC","sourcesContent":["/* \n  Global font override\n  This file overrides all font-family definitions in the application\n  to use the new font stack\n*/\n\n/* Override all elements to use the new font stack */\n* {\n  font-family: var(--font-fk-grotesk-neue) !important;\n}\n\n/* Override specific font classes from the existing codebase */\n.montser-lrg,\n.montser-mdm,\n.montser-sml,\n.montser-lrg-heading-sml,\n.montser-MediumItalic,\n.montser-ThinItalic,\n.montser-LightItalic,\n.montser-sml-LightItalic,\n.montser-sml-LightItalic-1rem,\n.montser-lrg-LightItalic,\n.monster-lrg-italic-normal,\n.monster-mdm-italic-normal,\n.montser-sml-italic,\n.montser-sml-bold,\n.montser-lrg-bold-re,\n.montser-lrg-bold-0-8rem,\n.monster-lrg-normal,\n.montser-mdm-2,\n.montser-mdm-1rem,\n.montser-mdm-italic,\n.montser-mdm,\n.montser-sml,\n.montser-sml-italic,\n.montser-sml-bold,\n.montser-lrg-bold,\n.monster-lrg-normal,\n.monster-lrg-italic,\n.text-virtu-header,\n.custom-large-heading,\n.custom-extra-large-heading,\n.reg-montser-lrg {\n  font-family: var(--font-fk-grotesk-neue) !important;\n  /* Keep other styling like weight, size, style */\n}\n\n/* Override Bootstrap and other framework fonts */\nbody,\nbutton,\ninput,\nselect,\ntextarea,\n.nav-link,\n.navbar-brand,\n.btn,\n.dropdown-item,\nh1, h2, h3, h4, h5, h6,\np, span, div, a {\n  font-family: var(--font-fk-grotesk-neue) !important;\n}\n\n/* Replace the --sans variable */\n:root {\n  --sans: var(--font-fk-grotesk-neue);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
