import React from 'react';

export default function HindiKeyboard({ pressedKey, isCorrect }) {
  // Function to determine the class for highlighting keys
  const getKeyClass = (keyCode) => {
    if (pressedKey === keyCode) {
      return isCorrect ? 'highlight-correct' : 'highlight-wrong';
    }
    return '';
  };

  return (
    <figure className="kbBtnsWrap Hindi">
      {/* Numerics Row */}
      <div className="kbBtnsRow numericsRow montser-mdm d-flex flex-row justify-content-center align-items-center w-100">
        {[
          { key: '192', shift: '', normal: '' },
          { key: '49', shift: 'ऍ', normal: '१' },
          { key: '50', shift: 'ॅ', normal: '२' },
          { key: '51', shift: '#', normal: '३' },
          { key: '52', shift: '$', normal: '४' },
          { key: '53', shift: '', normal: '५' },
          { key: '54', shift: '', normal: '६' },
          { key: '55', shift: '', normal: '७' },
          { key: '56', shift: '', normal: '८' },
          { key: '57', shift: '(', normal: '९' },
          { key: '48', shift: ')', normal: '०' },
          { key: '173', shift: 'ः', normal: '-' },
          { key: '61', shift: 'ऋ', normal: 'ृ' },
        ].map(({ key, shift, normal }) => (
          <button
            key={key}
            className={`signNumeric ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <span className="shiftPress">{shift}</span>
            <span className="normalPress">{normal}</span>
          </button>
        ))}
        <button className={`kbHero backspaceBtn ${getKeyClass('8')}`} type="button" data-key-code-val="8">
          Backspace
        </button>
      </div>

      {/* Tab Row */}
      <div className="kbBtnsRow tabRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero tabBtn ${getKeyClass('9')}`} type="button" data-key-code-val="9">
          Tab
        </button>
        {[
          { key: '81', english: 'औ', hindi: 'ौ', label: 'Q' },
          { key: '87', english: 'ऐ', hindi: 'ै', label: 'W' },
          { key: '69', english: 'आ', hindi: 'ा', label: 'E' },
          { key: '82', english: 'ई', hindi: 'ी', label: 'R' },
          { key: '84', english: 'ऊ', hindi: 'ू', label: 'T' },
          { key: '89', english: 'भ', hindi: 'ब', label: 'Y' },
          { key: '85', english: 'ङ', hindi: 'ह', label: 'U' },
          { key: '73', english: 'घ', hindi: 'ग', label: 'I' },
          { key: '79', english: 'ध', hindi: 'द', label: 'O' },
          { key: '80', english: 'झ', hindi: 'ज', label: 'P' },
          { key: '219', shift: 'ढ', normal: 'ड', label: '[' },
          { key: '221', shift: 'ञ', normal: '़', label: ']' },
          { key: '220', shift: '|', normal: '\\', label: '\\' },
        ].map(({ key, english, hindi, shift, normal, label }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <span className="englishChar">{english}</span>
            <span className="otherlangChar">{hindi}</span>
            <span className="hindiLang montser-mdm">{label}</span>
          </button>
        ))}
      </div>

      {/* Caps Lock Row */}
      <div className="kbBtnsRow capsLockRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero capLockBtn ${getKeyClass('20')}`} type="button" data-key-code-val="20">
          Caps Lock
        </button>
        {[
          { key: '65', english: 'ओ', hindi: 'ो', label: 'A' },
          { key: '83', english: 'ए', hindi: 'े', label: 'S' },
          { key: '68', english: 'अ', hindi: '्', label: 'D' },
          { key: '70', english: 'इ', hindi: 'ि', label: 'F' },
          { key: '71', english: 'उ', hindi: 'ु', label: 'G' },
          { key: '72', english: 'फ', hindi: 'प', label: 'H' },
          { key: '74', english: 'ऱ', hindi: 'र', label: 'J' },
          { key: '75', english: 'ख', hindi: 'क', label: 'K' },
          { key: '76', english: 'थ', hindi: 'त', label: 'L' },
          { key: '59', shift: 'छ', normal: 'च', label: ';' },
          { key: '222', shift: 'ठ', normal: '', label: '\'' },
        ].map(({ key, english, hindi, shift, normal, label }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <span className="englishChar">{english}</span>
            <span className="otherlangChar">{hindi}</span>
            <span className="hindiLang montser-mdm">{label}</span>
          </button>
        ))}
        <button className={`kbHero enterBtn ${getKeyClass('13')}`} type="button" data-key-code-val="13">
          Enter
        </button>
      </div>

      {/* Shift Row */}
      <div className="kbBtnsRow shiftRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero firstShiftBtn ${getKeyClass('16')}`} type="button" data-key-code-val="16">
          Shift
        </button>
        {[
          { key: '90', english: '', hindi: '', label: 'Z' },
          { key: '88', english: 'ँ', hindi: 'ं', label: 'X' },
          { key: '67', english: 'ण', hindi: 'म', label: 'C' },
          { key: '86', english: '', hindi: 'न', label: 'V' },
          { key: '66', english: '', hindi: 'व', label: 'B' },
          { key: '78', english: 'ळ', hindi: 'ल', label: 'N' },
          { key: '77', english: 'श', hindi: 'स', label: 'M' },
          { key: '188', shift: 'ष', normal: ',', label: ',' },
          { key: '190', shift: '।', normal: '.', label: '.' },
          { key: '191', shift: 'य़', normal: 'य', label: '/' },
        ].map(({ key, english, hindi, shift, normal, label }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <span className="englishChar">{english}</span>
            <span className="otherlangChar">{hindi}</span>
            <span className="hindiLang montser-mdm">{label}</span>
          </button>
        ))}
        <button className={`kbHero secondShiftBtn ${getKeyClass('16')}`} type="button" data-key-code-val="16">
          Shift
        </button>
      </div>

      {/* Space Row */}
      <div className="kbBtnsRow spaceRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero spaceBtn ${getKeyClass('32')}`} type="button" data-key-code-val="32">
          Space
        </button>
      </div>
    </figure>
  );
}