import React, { useState, useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import axios from 'axios';

const Navbar = ({ bgColorClass }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);
  const [bgColor, setBgColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const subDomain = localStorage.getItem('subDomain');
  const herobgColor =localStorage.getItem("herobgColor")
  const herofontColor = localStorage.getItem("heroFontColor")
  let schoolName = subDomain;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          setBgColor(response.data.backgroundColor);
          setFontColor(response.data.fontColor);
  
          const logoUrl = response.data.logo.logo;
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl, response.data.fontColor);  // Pass fontColor here
          } else {
            setLogoContent(logoUrl);  // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        setError("Error loading data");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [schoolName]);
  
  const fetchAndModifySVG = async (svgUrl, fontColor) => {  
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', fontColor);   // Use the passed fontColor
          path.setAttribute('stroke', fontColor); 
        });
        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrolling(currentScrollPos > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
      if (openDropdownIndex !== null && !e.target.closest('.dropdown-menu') && !e.target.closest('.nav-item')) {
        setOpenDropdownIndex(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed, openDropdownIndex]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleJoinClick = () => {
    if(subDomain=='Contact Center Academy'){
      const loginLink = `https://contactcenter.app.virtupreplabs.com/join`;
      window.open(loginLink, '_blank');
    }else{
      const joinLink = `https://${subDomain}.app.virtupreplabs.com/join`;
      window.open(joinLink, '_blank');
    };
  };

  const redirectProtocol = (name) => {
    console.log("redirectProtocol clickedd==>",name)
    if(name == "Protocol"){
      const link = `https://realmeritprotocol.com/docs/`;
      window.open(link, '_blank');
    }else{
      const link = `https://learningwithoutgatekeepers.com/`;
      window.open(link, '_blank');
    }
    
  };

  const handleLoginClick = () => {
    if(subDomain=='Contact Center Academy'){
    const loginLink = `https://contactcenter.app.virtupreplabs.com`;
    window.open(loginLink, '_blank');
    }else{
      const loginLink = `https://${subDomain}.app.virtupreplabs.com`;
      window.open(loginLink, '_blank');
    }
    
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <nav className={`navbar navbar-expand-lg position-fixed ht-10vh`} style={{ backgroundColor: bgColor||'#1c2933', color: fontColor||'#ffe6c8' }}>
      <div className="container-fluid vp-container no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-0 maxw-265 w-100 me-0'>
          <div className="siteLogo">
            <a href="/">
              {subDomain === "virtupreplabs" ? (
                <img src="/logos/VP3d.svg" alt="Logo" width="50px" height="20px" />
              ) : (
                logoContent ? (
                  logoContent.includes('<svg') ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: logoContent }}
                      className="svg-rotate"
                      style={{ display: 'block' }}
                    />
                  ) : (
                    <img src={logoContent} alt="Logo" width="50px" height="20px" />
                  )
                ) : (
                  <i className={data.logo.iconClass}></i>
                )
              )}
            </a>
          </div>
          <a className="common-textshadow montser-lrg ms-2 text-decoration-none" 
              style={{color:fontColor || '#ffe6c8',whiteSpace:'nowrap'}} 
              href={subDomain === "virtupreplabs" ? "/" : data.brand.href}>
            <span className='montser-lrg-1rem common-textshadow'>{subDomain === "virtupreplabs" ? "Virtu Prep" : data.brand.name}</span>
          </a>
        </div>
        <button
          className="navbar-toggler border-0"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          <span className={`${scrolling ? 'text-white' : ''}`}>
            <span className="f-ui-1 montser-lrg common-textshadow" style={{color:fontColor||"#1c2933"}}>Menu</span>
          </span>
        </button>
        <div className={`collapse navbar-collapse mobile-menu mobile-bg-color ${isMobile ? 'common-boxshadow' : ''} ${!isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent" 
         style={{
            backgroundColor: schoolName === "virtupreplabs" 
            ? (isMobile ? '#ffe6c8' : '#1c2933') 
            : (isMobile ? (fontColor !== 'undefined' ? fontColor : '#ffe6c8'):undefined),
            color:schoolName === "virtupreplabs" 
            ? (isMobile ? '#1c2933' : '#ffe6c8') 
            : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933'):undefined)
        }}
         >
          <ul className={`w-100 navbar-nav d-flex align-items-start align-items-lg-center justify-content-center center me-5 mb-2 mb-lg-0 header-txt-margin gap-4 
          ${!isMobile && schoolName === "virtupreplabs" ? "" : ""}`}>
            {data.menu.map((item, index) => {
              if (item.name === "Writing" && subDomain !== "virtupreplabs") {
                return null;
              }
              return (
                <li className="nav-item" key={index}  >
                  {!item.subMenu ? (
                    item.name === 'Protocol' || item.name === 'Handbook' ? (
                      <a 
                        className="d-flex align-items-center navbar-brand common-textshadow montser-lrg-1rem m-0" 
                        
                        style={{ color: fontColor || "#1c2933" }}
                      >
                        <span className='text-decorator-underline text-wrap'
                        style={{
                          color: schoolName === "virtupreplabs" 
                            ? (isMobile ? '#1c2933' : '#ffe6c8') 
                            : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933') : (fontColor !== 'undefined' ? fontColor : '#ffe6c8')),
                        }}
                        onClick={() => redirectProtocol(item.name)}>
                          {item.name}
                        </span>
                      </a>
                    ) : (
                      <a 
                        className="d-flex align-items-center navbar-brand common-textshadow montser-lrg-1rem m-0" 
                        href={item.href} 
                        style={{ color: fontColor || "#1c2933" }}
                      >
                        <span 
                          className='text-decorator-underline text-wrap' 
                          style={{
                            color: schoolName === "virtupreplabs" 
                              ? (isMobile ? '#1c2933' : '#ffe6c8') 
                              : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933') : (fontColor !== 'undefined' ? fontColor : '#ffe6c8')),
                          }}
                        >
                          {item.name}
                        </span>
                      </a>
                    )
                  ) : 
                  (
                  <div
                      className="d-flex align-items-center gap-2 text-yellow common-textshadow"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleDropdownToggle(index)}
                    >
                      <span className='navbar-brand common-textshadow montser-lrg-1rem m-0 nav-item-span nav-context-menu-title' 
                        //  style={{color:isMobile?fontColor?fontColor:'#1c2933':fontColor?fontColor:"#ffe6c8"}}
                         style={{
                          color: schoolName === "virtupreplabs" 
                            ? (isMobile ? '#1c2933' : '#ffe6c8') 
                            : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933') : (fontColor !== 'undefined' ? fontColor : '#ffe6c8')),
                                      }}
                      >{item.name}</span>
                      <div className={`${openDropdownIndex === index ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className='demo-icon icon-opened-folder dropdown-icon-size' 
                        style={{color:isMobile?bgColor?bgColor:'#1c2933':fontColor?fontColor:"#ffe6c8"}}
                          ></i>
                      </div>
                    </div>
                  )}
                  {item.subMenu && (
                    <ul className={`dropdown-menu ${openDropdownIndex === index ? 'show' : ''}`} 
                    aria-labelledby={`navbarDropdown${index}`} 
                    style={{ backgroundColor:isMobile?bgColor||'#1c2933':fontColor||'#ffe6c8', color:isMobile?fontColor||'#ffe6c8': bgColor||'#1c2933' }}
                             >
                       {item.subMenu.map((subItem, subIndex) => {
                        const hrefPrefix = item.name === "Features" ? "feature" : "educator";
                        return (
                          <li className="ms-1 pb-2" key={subIndex}>
                            <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow"  href={`/${hrefPrefix}=${subItem.name}`}>
                              <span className='text-decorator-underline text-wrap' 
                              style={{color:isMobile?fontColor?fontColor:"#ffe6c8":bgColor?bgColor:'#1c2933'}}
                              
                              >
                                {subItem.name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="d-flex gap-3 justify-content-end">
            <a className="btn-login btn common-btn common-textshadow montser-lrg-1rem text-decoration-none" onClick={handleLoginClick} 
                style={{
                  color: schoolName === "virtupreplabs" 
                    ? (isMobile ? '#1c2933' : '#ffe6c8') 
                    : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933') : (fontColor !== 'undefined' ? fontColor : '#ffe6c8')),
                   }}
                
              >
              Login
            </a>
            <a 
                className="btn-join btn common-btn common-textshadow montser-lrg-1rem text-decoration-none" 
                onClick={handleJoinClick}
                style={{
                  color: schoolName === "virtupreplabs" 
                    ? (isMobile ? '#1c2933' : '#ffe6c8') 
                    : (isMobile ? (bgColor !== 'undefined' ? bgColor : '#1c2933') : (fontColor !== 'undefined' ? fontColor : '#ffe6c8')),
                   }}
                
                >
              
              Join
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
