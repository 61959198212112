import React, {  useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import BlogDashboard from '../blogs/BlogDashboard'
import Aitutor from '../blogs/Aitutor'
import Blog_1 from '../blogs/Blog_1'

import Education from '../blogs/Education'
import Repetition from '../blogs/Repetition'
import Shadows from '../blogs/Shadows'
import Kaleidoscope from '../blogs/Kaleidoscope'
import Matrix from '../blogs/Matrix'
import Neuroscience from '../blogs/Neuroscience'
import ResearchDashboard from '../research/ResearchDashboard'
import Aboutus from '../about_us/Aboutus'
import VideoBackground from '../components/VideoBackground'
import ScrollItems from '../components/ScrollItems'
import useSession from '../hooks/useSession'
import { useLocation } from 'react-router-dom';
import Features from '../sections/Features';
import Roles from '../sections/Roles';
import axios from 'axios' 
import Navbar from '../components/Navbar'
import VideoBackgroundforLanding from '../components/VideoBackgroundForLanding'
import PracticeModal from '../components/PracticeModal'

export default function AppNavigations() {
  const type = window.location.href.split('=')[0].split('/').pop();
  const [videoLink, setVideoLink] = useState('');
  const [imageLink, setImageLink] = useState('');
  const [overlayText, setOverlayText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Initialize colors based on theme preference - using --bg-main directly
  const [bgColor, setBgColor] = useState('var(--bg-main)');
  const [fontColor, setFontColor] = useState('var(--text-primary)');
  const dynamicVideoLink = "videos/Virtu Prep Landing 1 Vid.mp4";
  const { setSubDomain } = useSession();
  const location = useLocation();

  setSubDomain();
  const subDomain = localStorage.getItem('subDomain');
  const pageName = "mainPage";
  const buttonText = "About Us";
  let schoolName = subDomain;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getAboutUsPageDetails', {
          params: { school: schoolName },
        });
        if (response.data.success == false) {
          setError(response.data.message);
        } else {
          localStorage.setItem("abtFontColor",response.data.fontColor)
          localStorage.setItem("abtBgColor",response.data.backgroundColor)
          localStorage.setItem("abtusImage",response.data.imageLink)
        }
      } catch (err) {
        setError("Error loading about us page details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [schoolName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getVideoLink', {
          params: { school: schoolName },
        });
        if (response.data.success!=false) {
          setVideoLink(response.data.videoLink);
          setImageLink(response.data.imageLink)
          setOverlayText(response.data.overlayText);
          setBgColor(response.data.backgroundColor);
          setFontColor(response.data.fontColor);
          localStorage.setItem("heroFontColor", response.data.fontColor);
          localStorage.setItem("herobgColor", response.data.backgroundColor);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("Error loading video link");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [schoolName]);
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='mainSectionDiv' 
    style={{
      background: location.pathname === '/about-us' ? localStorage.getItem("abtBgColor") || 'var(--bg-main)' : 'var(--bg-main)',
      color: location.pathname === '/about-us' ? localStorage.getItem("abtFontColor") || 'var(--text-primary)' : 'var(--text-primary)'
    }}>
      {subDomain !== 'virtupreplabs' && (
        <Navbar bgColorClass="" />
      )}
      {subDomain === 'virtupreplabs' && (
        <Navbar bgColorClass="bg-transparent" />
      )}
      <div className="content-with-sidebar">
        <Routes>
          {type === 'feature' && (
            <Route path="/:feature" element={<Features/>} />
          )}
          {type === 'educator' && (
            <Route path="/:role" element={<Roles/>} />
          )}
          {subDomain !== 'virtupreplabs' && (
            <Route path="/videoBackground" element={<VideoBackground />} />
          )}
          {subDomain === 'virtupreplabs' && (
            <Route path="/VideoBackgroundforLanding" element={<VideoBackgroundforLanding />} />
          )}
          {/* //<Route path="/PracticeModal" element={<PracticeModal/>}/> */}
          <Route path="/blog" element={<Blog_1 />} />
          <Route path="/blogdashboard" element={<BlogDashboard dataPath="/blogDashboardData.json" />} />
          <Route path="/blog1" element={<Aitutor />} />
          <Route path="/blog2" element={<Education />} />
          <Route path="/blog3" element={<Repetition />} />
          <Route path="/blog4" element={<Shadows />} />
          <Route path="/blog5" element={<Kaleidoscope />} />
          <Route path="/blog6" element={<Matrix />} />
          <Route path="/blog7" element={<Neuroscience />} />
          <Route path="/research" element={<ResearchDashboard dataPath="/researchDashboardData.json" />} />
          <Route path="/about-us" element={<Aboutus />} />
          {subDomain !== 'virtupreplabs' && (
            <Route path="/" element={<>
              <VideoBackground videoLink={videoLink} imageLink={imageLink} overlayText={overlayText} buttonText={buttonText} fontColor={fontColor} backgroundColor={bgColor}/>

              <ScrollItems />
            </>} />
          )}
          {subDomain === 'virtupreplabs' && (
            <Route path="/" element={<>
              <VideoBackgroundforLanding videoLink={dynamicVideoLink} buttonText={buttonText} pageName={pageName} />
              <ScrollItems />
            </>} />
          )}
        </Routes>
      </div>
    </div>
  );
}
