import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './font-override.css';

var hostname = window.location.hostname.split(".")[0] ;
if(hostname=="www"){
  hostname = window.location.hostname.split(".")[1] ;
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App hostname={hostname} />
  </React.StrictMode>
);

reportWebVitals();
