import React, { useState, useRef, useEffect } from 'react';
import '../global.css';
import axios from 'axios';
import './videobackground.css';
import EvidenceModal from './EvidenceModal';
import PracticeModal from './PracticeModal';

const VideoBackgroundforLanding = ({ videoLink, buttonText, pageName }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPracticeModalOpen, setPracticeModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getReadyToViewSchool', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data.schools);
        }
      } catch (err) {
        setError("Error loading scroll items data");
      }
    };

    fetchData();
  }, [schoolName]);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = 0.5;
    }
  }, []);

  const handleAboutClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };

  const handleJoinClick = () => {
    window.open("https://learning.virtupreplabs.com/join", "_blank");
  };

  const redirectOnChange = (event) => {
    let selectedValue = event.target.dataset.value;
    selectedValue = selectedValue.toLowerCase();
    if (!selectedValue) return;

    const schoolDomains = {
      school: ["africanlab", "buffalovirtual", "astrophysics", "masa", "schoolchoice", "secondchance", "mychoice", "stemity", "thepublic", "salamshalom", "cryptoLaw", "rethink", "theemergency", "rethink", ""],
      com: ["virtuprep", "proteacherprep", "turkprep", "elaprep", "finlitprep", "greenworldacademy", "nyprepschool"],
      academy: ["athome", "contactcenter", "extracredit", "parentprep", "jewishlearning", "logicalmind", "reoperations", "amisrael", "autismprep", "jee", "militarytraining", "neurolingo", "saad", "specialneeds", "debate", "capacity", "defendingisrael", "knicks", "businessOperations", "growingtree", "realmerit"],
      training: ["proai", "aihi", "eos"],
      college: ["realmeritcollege", "merit"],
      virtuprep: ["schoolofimpact", "sampleschool"],
      institute: ["legaloperations", "govai", "ailaw", "sikhlegacy"]
    };

    for (let [domain, values] of Object.entries(schoolDomains)) {
      if (values.includes(selectedValue)) {
        window.open(`https://${selectedValue}.${domain}`);
        return;
      }
    }

    window.open(`https://${selectedValue}.virtupreplabs.com`);
  };

  if (pageName !== "mainPage") return null;

  const handleEvidenceClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const togglePracticeModal = () => {
    setPracticeModalOpen(!isPracticeModalOpen);
  };

  return (
    <div 
      className="video-background video-background-landing d-flex justify-content-center align-items-center" 
      id="mainPage"
      style={{ backgroundColor: 'var(--bg-main)', maxWidth:"100%", margin: "0 auto" }}
    >
      <div 
        className='vp-container video-bg-1 w-100 d-flex align-items-center justify-content-center h-100'
        style={{ backgroundColor: 'transparent' }}
      >
        <div 
          className="video-text justify-content-center text-yellow d-flex w-100 px-md-5"
          style={{ backgroundColor: 'transparent' }}
        >
          <div 
            className="mainPageContainer d-flex align-center justify-content-center w-100"
            style={{ backgroundColor: 'transparent' }}
          >
            <div className="container-fluid main-content position-relative w-100" style={{ backgroundColor: 'var(--bg-main)', margin: "0 auto" }}>
              <div className="search-container">
                <h2 className="search-heading text-center mb-4">What do you want to learn?</h2>
                <div className="search-panel glass-effect p-4">
                  <div className="panel-video-container">
                    <video className="panel-video" ref={videoRef} autoPlay loop muted playsInline>
                      <source src={videoLink} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  
                  <div className="row w-100">
                    {/* Left side buttons */}
                    <div className="col-12 col-md-2 d-flex flex-column align-items-start justify-content-center gap-4 side-buttons">
                      <button 
                        className="landing-btn evidence-btn" 
                        onClick={handleEvidenceClick}
                      >
                        Evidence
                      </button>
                      <button 
                        className="landing-btn about-us-btn" 
                        onClick={handleAboutClick}
                      >
                        About Us
                      </button>
                    </div>
                    
                    {/* Center search area */}
                    <div className="col-12 col-md-8 d-flex flex-column align-items-center justify-content-center">
                      <div className="search-input-container w-100 position-relative">
                        <textarea 
                          className="search-textarea w-100" 
                          placeholder=""
                        ></textarea>
                        <button 
                          className="search-submit-btn" 
                          onClick={togglePracticeModal} 
                          aria-label="Submit search"
                        >
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 20V4M12 4L8 8M12 4L16 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                      </div>
                      
                      {/* Topic buttons */}
                      <div className="topic-tags mt-3">
                        <button className="topic-tag">AI</button>
                        <button className="topic-tag">Coding</button>
                        <button className="topic-tag">Data</button>
                        <button className="topic-tag">STEM</button>
                        <button className="topic-tag">Digital</button>
                        <button className="topic-tag">VR/AR</button>
                      </div>
                    </div>
                    
                    {/* Right side buttons */}
                    <div className="col-12 col-md-2 d-flex flex-column align-items-end justify-content-center gap-4 side-buttons">
                      <button 
                        className="landing-btn join-btn" 
                        onClick={handleJoinClick}
                      >
                        Join
                      </button>
                      <div className="dropdown">
                        <button 
                          className="landing-btn find-btn dropdown-toggle" 
                          type="button" 
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Find
                        </button>
                        <ul className="dropdown-menu scrollable-dropdown">
                          {data && data.map((school) => (
                            <li key={school} className="dropdown-item" onClick={redirectOnChange} data-value={school.url}>
                              {school.name || school.url}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Scroll indicator */}
              <div className="text-center scroll-indicator">
                <p className="text-light">Scroll for more ↓</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Modals */}
        <EvidenceModal isOpen={isModalOpen} onClose={handleCloseModal} />
        <PracticeModal isOpen={isPracticeModalOpen} onClose={togglePracticeModal} />
      </div>
    </div>
  );
};

export default VideoBackgroundforLanding;