import React from 'react';

export default function HebrewKeyboard({ pressedKey, isCorrect }) {
  // Function to determine the class for highlighting keys
  const getKeyClass = (keyCode) => {
    if (pressedKey === keyCode) {
      return isCorrect ? 'highlight-correct' : 'highlight-wrong';
    }
    return '';
  };
  
  return (
    <figure className="kbBtnsWrap Hebrew">
      {/* Numerics Row */}
      <div className="kbBtnsRow numericsRow montser-mdm d-flex flex-row justify-content-center align-items-center w-100">
        {[
          { key: '192', english: '`', shift: '~', hebrew: '' },
          { key: '49', english: '1', shift: '!', hebrew: '' },
          { key: '50', english: '2', shift: '@', hebrew: '' },
          { key: '51', english: '3', shift: '#', hebrew: '€' },
          { key: '52', english: '4', shift: '$', hebrew: '₪' },
          { key: '53', english: '5', shift: '%', hebrew: '°' },
          { key: '54', english: '6', shift: '^', hebrew: '֫' },
          { key: '55', english: '7', shift: '&', hebrew: 'ֽ' },
          { key: '56', english: '8', shift: '*', hebrew: '×' },
          { key: '57', english: '9', shift: '(', hebrew: '' },
          { key: '48', english: '0', shift: ')', hebrew: '' },
          { key: '173', english: '-', shift: '_', hebrew: '־' },
          { key: '61', english: '=', shift: '+', hebrew: '' },
        ].map(({ key, english, shift, hebrew }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <div className="corner top-left">
              <span className="englishChar shiftPress englishCharFont">{shift}</span>
            </div>
            <div className="corner top-right">
              <span className="otherlangChar normalPress otherlangFont">{english}</span>
            </div>
            <div className="corner bottom-left">
              <span className="extraChar">{hebrew}</span>
            </div>
            <div className="corner bottom-right">
              <span className="otherlangChar shiftPress otherlangFont"></span>
            </div>
          </button>
        ))}
        <button className={`kbHero backspaceBtn ${getKeyClass('8')}`} type="button" data-key-code-val="8">
          Backspace
        </button>
      </div>

      {/* Tab Row */}
      <div className="kbBtnsRow tabRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero tabBtn ${getKeyClass('9')}`} type="button" data-key-code-val="9">
          Tab
        </button>
        {[
          { key: '81', english: 'Q', hebrew: '/' },
          { key: '87', english: 'W', hebrew: '\'' },
          { key: '69', english: 'E', hebrew: 'ק' },
          { key: '82', english: 'R', hebrew: 'ר' },
          { key: '84', english: 'T', hebrew: 'א' },
          { key: '89', english: 'Y', hebrew: 'ט' },
          { key: '85', english: 'U', hebrew: 'ו' },
          { key: '73', english: 'I', hebrew: 'ן' },
          { key: '79', english: 'O', hebrew: 'ם' },
          { key: '80', english: 'P', hebrew: 'פ' },
          { key: '219', english: '[', hebrew: ']' },
          { key: '221', english: ']', hebrew: '[' },
          { key: '220', english: '\\', hebrew: '|' },
        ].map(({ key, english, hebrew }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <div className="corner top-left">
              <span className="englishChar englishCharFont">{english}</span>
            </div>
            <div className="corner top-right">
              <span className="otherlangChar center-otherlangFont">{hebrew}</span>
            </div>
          </button>
        ))}
      </div>

      {/* Caps Lock Row */}
      <div className="kbBtnsRow capsLockRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero capLockBtn ${getKeyClass('20')}`} type="button" data-key-code-val="20">
          Caps Lock
        </button>
        {[
          { key: '65', english: 'A', hebrew: 'ש' },
          { key: '83', english: 'S', hebrew: 'ד' },
          { key: '68', english: 'D', hebrew: 'ג' },
          { key: '70', english: 'F', hebrew: 'כ' },
          { key: '71', english: 'G', hebrew: 'ע' },
          { key: '72', english: 'H', hebrew: 'י' },
          { key: '74', english: 'J', hebrew: 'ח' },
          { key: '75', english: 'K', hebrew: 'ל' },
          { key: '76', english: 'L', hebrew: 'ך' },
          { key: '59', english: ';', hebrew: 'ף' },
          { key: '222', english: '\'', hebrew: ',' },
        ].map(({ key, english, hebrew }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <div className="corner top-left">
              <span className="englishChar englishCharFont">{english}</span>
            </div>
            <div className="corner top-right">
              <span className="otherlangChar center-otherlangFont">{hebrew}</span>
            </div>
          </button>
        ))}
        <button className={`kbHero enterBtn ${getKeyClass('13')}`} type="button" data-key-code-val="13">
          Enter
        </button>
      </div>

      {/* Shift Row */}
      <div className="kbBtnsRow shiftRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero firstShiftBtn ${getKeyClass('16')}`} type="button" data-key-code-val="16">
          Shift
        </button>
        {[
          { key: '90', english: 'Z', hebrew: 'ז' },
          { key: '88', english: 'X', hebrew: 'ס' },
          { key: '67', english: 'C', hebrew: 'ב' },
          { key: '86', english: 'V', hebrew: 'ה' },
          { key: '66', english: 'B', hebrew: 'נ' },
          { key: '78', english: 'N', hebrew: 'מ' },
          { key: '77', english: 'M', hebrew: 'צ' },
          { key: '188', english: ',', hebrew: 'ת' },
          { key: '190', english: '.', hebrew: 'ץ' },
          { key: '191', english: '/', hebrew: '.' },
        ].map(({ key, english, hebrew }) => (
          <button
            key={key}
            className={`alphaBtn ${getKeyClass(key)}`}
            type="button"
            data-key-code-val={key}
          >
            <div className="corner top-left">
              <span className="englishChar englishCharFont">{english}</span>
            </div>
            <div className="corner top-right">
              <span className="otherlangChar center-otherlangFont">{hebrew}</span>
            </div>
          </button>
        ))}
        <button className={`kbHero secondShiftBtn ${getKeyClass('16')}`} type="button" data-key-code-val="16">
          Shift
        </button>
      </div>

      {/* Space Row */}
      <div className="kbBtnsRow spaceRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button className={`kbHero spaceBtn ${getKeyClass('32')}`} type="button" data-key-code-val="32">
          Space
        </button>
      </div>
    </figure>
  );
}