import React from 'react';

export default function SpanishKeyboard() {
  return (
    <figure className="kbBtnsWrap Spanish">
      {/* Numerics Row */}
      <div className="kbBtnsRow numericsRow montser-mdm d-flex flex-row justify-content-center align-items-center w-100">
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="192"
          data-finger="babyFingerLeft"
        >
          <span className="shiftPress">~</span>
          <span className="normalPress">`</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="49"
          data-finger="babyFingerLeft"
        >
          <span className="shiftPress">!</span>
          <span className="normalPress sNormalPress">1</span>
          <span className="spanishPress">|</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="50"
          data-finger="ringFingerLeft"
        >
          <span className="shiftPress">"</span>
          <span className="normalPress sNormalPress">2</span>
          <span className="spanishPress">@</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="51"
          data-finger="middleFingerLeft"
        >
          <span className="shiftPress">#</span>
          <span className="normalPress sNormalPress">3</span>
          <span className="spanishPress">#</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="52"
          data-finger="indexFingerLeft"
        >
          <span className="shiftPress">$</span>
          <span className="normalPress sNormalPress">4</span>
          <span className="spanishPress">~</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="53"
          data-finger="indexFingerLeft"
        >
          <span className="shiftPress">%</span>
          <span className="normalPress sNormalPress">5</span>
          <span className="spanishPress">€</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="54"
          data-finger="ringFingerRight"
        >
          <span className="shiftPress">&amp;</span>
          <span className="normalPress sNormalPress">6</span>
          <span className="spanishPress">¬</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="55"
          data-finger="ringFingerRight"
        >
          <span className="shiftPress">/</span>
          <span className="normalPress">7</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="56"
          data-finger="middleFingerRight"
        >
          <span className="shiftPress">(</span>
          <span className="normalPress">8</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="57"
          data-finger="indexFingerRight"
        >
          <span className="shiftPress">)</span>
          <span className="normalPress">9</span>
        </button>
        <button
          className="signNumeric"
          type="button"
          data-key-code-val="48"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">=</span>
          <span className="normalPress">0</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="173"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">?</span>
          <span className="normalPress">'</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="61"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">¿</span>
          <span className="normalPress">¡</span>
        </button>
        <button
          className="kbHero backspaceBtn"
          type="button"
          data-key-code-val="8"
          data-finger="babyFingerRight"
        >
          Backspace
        </button>
      </div>

      {/* Tab Row */}
      <div className="kbBtnsRow tabRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button
          className="kbHero tabBtn"
          type="button"
          data-key-code-val="9"
          data-finger="babyFingerLeft"
        >
          Tab
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="81"
          data-finger="babyFingerLeft"
        >
          Q
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="87"
          data-finger="ringFingerLeft"
        >
          W
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="69"
          data-finger="middleFingerLeft"
        >
          <span className="noAposPress">E</span>
          <span className="aposPress d-none">é</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="82"
          data-finger="indexFingerLeft"
        >
          R
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="84"
          data-finger="indexFingerLeft"
        >
          T
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="89"
          data-finger="ringFingerRight"
        >
          Y
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="85"
          data-finger="ringFingerRight"
        >
          <span className="noAposPress">U</span>
          <span className="aposPress d-none">ú</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="73"
          data-finger="middleFingerRight"
        >
          <span className="noAposPress">I</span>
          <span className="aposPress d-none">í</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="79"
          data-finger="indexFingerRight"
        >
          <span className="noAposPress">O</span>
          <span className="aposPress d-none">ó</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="80"
          data-finger="babyFingerRight"
        >
          P
        </button>
        <button
          className="doubleSigns purple-color"
          type="button"
          data-key-code-val="222"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">^</span>
          <span className="normalPress">´</span>
          <span className="spanishPress">[</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="221"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">*</span>
          <span className="normalPress">+</span>
          <span className="spanishPress">]</span>
        </button>
        <button
          className="kbHero enterBtn"
          type="button"
          data-key-code-val="13"
          data-finger="babyFingerRight"
        >
          Enter
        </button>
      </div>

      {/* Caps Lock Row */}
      <div className="kbBtnsRow capsLockRow montser-mdm  d-flex flex-row justify-content-center align-items-center">
        <button
          className="kbHero capLockBtn"
          type="button"
          data-key-code-val="20"
          data-finger="babyFingerLeft"
        >
          Caps Lock
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="65"
          data-finger="babyFingerLeft"
        >
          <span className="noAposPress">A</span>
          <span className="aposPress d-none">á</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="83"
          data-finger="ringFingerLeft"
        >
          S
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="68"
          data-finger="middleFingerLeft"
        >
          D
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="70"
          data-finger="indexFingerLeft"
        >
          F
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="71"
          data-finger="indexFingerLeft"
        >
          G
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="72"
          data-finger="ringFingerRight"
        >
          H
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="74"
          data-finger="ringFingerRight"
        >
          J
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="75"
          data-finger="middleFingerRight"
        >
          K
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="76"
          data-finger="indexFingerRight"
        >
          L
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="59"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">Ñ</span>
          <span className="normalPress">ñ</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="219"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">{'{'}</span>
          <span className="normalPress">¨</span>
          <span className="spanishPress">´</span>
        </button>
        <button
          className="doubleSigns backSlash"
          type="button"
          data-key-code-val="220"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">{'}'}</span>
          <span className="normalPress">Ç</span>
        </button>
      </div>

      {/* Shift Row */}
      <div className="kbBtnsRow shiftRow montser-mdm d-flex flex-row  justify-content-center align-items-center">
        <button
          className="kbHero firstShiftBtn"
          type="button"
          data-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="190"
          data-finger="indexFingerRight"
        >
          <span className="shiftPress">&gt;</span>
          <span className="normalPress">&lt;</span>
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="90"
          data-finger="babyFingerLeft"
        >
          Z
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="88"
          data-finger="ringFingerLeft"
        >
          X
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="67"
          data-finger="middleFingerLeft"
        >
          C
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="86"
          data-finger="indexFingerLeft"
        >
          V
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="66"
          data-finger="indexFingerLeft"
        >
          B
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="78"
          data-finger="ringFingerRight"
        >
          N
        </button>
        <button
          className="alphaBtn"
          type="button"
          data-key-code-val="77"
          data-finger="ringFingerRight"
        >
          M
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="188"
          data-finger="middleFingerRight"
        >
          <span className="shiftPress">;</span>
          <span className="normalPress">,</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="190"
          data-finger="indexFingerRight"
        >
          <span className="shiftPress">:</span>
          <span className="normalPress">.</span>
        </button>
        <button
          className="doubleSigns"
          type="button"
          data-key-code-val="191"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">_</span>
          <span className="normalPress">-</span>
        </button>
        <button
          className="kbHero secondShiftBtn"
          type="button"
          data-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
      </div>

      {/* Space Row */}
      <div className="kbBtnsRow spaceRow montser-mdm d-flex flex-row   justify-content-center align-items-center">
        <button
          className="kbHero spaceBtn"
          type="button"
          data-key-code-val="32"
          data-finger="thumb"
        ></button>
      </div>
    </figure>
  );
}
