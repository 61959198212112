import React, { useState, useEffect } from "react";
import KeyboardManager from "./KeyboardManager";

function CompletionPopup({ typingSpeed, elapsedTime, attentionScore, onNext, questionData, onComplete, userInfo, closeModal }) {
  // State to track the current step in the completion flow
  const [currentStep, setCurrentStep] = useState("results"); // "results", "question", "options", "openended", "textarea", "review", "analysis"
  const [selectedOption, setSelectedOption] = useState(null);
  // Add state for open-ended question answer
  const [openEndedAnswer, setOpenEndedAnswer] = useState("");
  // Add state for keyboard visibility
  const [showKeyboard, setShowKeyboard] = useState(true);
  // Add state for keyboard language
  const [keyboardLanguage, setKeyboardLanguage] = useState("English");
  // Add state for tracking pressed keys
  const [pressedKey, setPressedKey] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  // Add state for review
  const [reviewRating, setReviewRating] = useState(5);
  const [reviewComment, setReviewComment] = useState("");
  // Add state for comprehension rating
  const [comprehensionRating, setComprehensionRating] = useState(80);
  // Add state for analysis loading and results
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  // Add state for tracking current suggestion section
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  
  // Format time as MM:SS
  const formatTime = (seconds) => {
    // Add null check for undefined seconds
    if (seconds === undefined || seconds === null) {
      return '0:00';
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // Safely convert to string
    return `${minutes}:${(remainingSeconds !== undefined ? remainingSeconds.toString() : '0').padStart(2, '0')}`;
  };

  // Handle keyboard key press
  useEffect(() => {
    // Only add event listener when in textarea mode
    if (currentStep !== "textarea") return;
    
    const handleKeyDown = (e) => {
      // Set the pressed key for highlighting - ensure it's safe
      setPressedKey(e.key || '');
      setIsCorrect(true);
      
      // Clear the pressed key after a short delay
      setTimeout(() => {
        setPressedKey(null);
        setIsCorrect(null);
      }, 300);
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentStep]);

  // Handle next button click
  const handleNext = () => {
    if (currentStep === "results") {
      setCurrentStep("question");
    } else if (currentStep === "question") {
      setCurrentStep("options");
    } else if (currentStep === "options") {
      // After options, show the open-ended question
      // No need to call onComplete here yet
      setCurrentStep("openended");
    } else if (currentStep === "openended") {
      // After showing the open-ended question, show the textarea
      setCurrentStep("textarea");
    } else if (currentStep === "textarea") {
      // After submitting the open-ended answer, show the review popup
      setCurrentStep("review");
    } else if (currentStep === "review") {
      // Submit the review and complete the flow with ALL data
      console.log("Review step - Submit button clicked");
      
      // Create the complete review data object with all necessary information
      const reviewData = { 
        rating: reviewRating, 
        comment: reviewComment,
        comprehension: comprehensionRating,
        userInfo: userInfo || { firstName: '', lastName: '' },
        openEndedAnswer, // Add the open-ended answer directly to reviewData
        selectedOption, // Add selected option directly to reviewData
        setAnalysis, // Add the setAnalysis function so parent can update analysis
      };
      
      console.log("Complete review data being sent:", reviewData);
      
      // Show analyzing state
      setIsAnalyzing(true);
      setCurrentStep("analysis");
      
      // First call onComplete to update parent state
      if (onComplete) {
        onComplete(selectedOption, openEndedAnswer, reviewData);
        console.log("onComplete called with review data");
      }
      
      // Use a longer timeout to ensure state updates have time to propagate
      setTimeout(() => {
        console.log("Calling onNext after timeout to ensure data is saved");
        if (onNext) {
          onNext();
        }
      }, 500); // Increased timeout for state updates
    }
    // We've removed the analysis step case since it's now handled by dedicated buttons
  };

  // Handle option selection
  const handleOptionSelect = (optionId) => {
    setSelectedOption(optionId);
  };

  // Handle open-ended answer input
  const handleOpenEndedAnswerChange = (e) => {
    setOpenEndedAnswer(e.target.value);
  };

  // Handle review rating change
  const handleReviewRatingChange = (e) => {
    setReviewRating(parseInt(e.target.value, 10));
  };

  // Handle review comment change
  const handleReviewCommentChange = (e) => {
    setReviewComment(e.target.value);
  };

  // Add handler for comprehension rating change
  const handleComprehensionChange = (e) => {
    setComprehensionRating(parseInt(e.target.value, 10));
  };

  // Toggle keyboard visibility
  const toggleKeyboard = () => {
    setShowKeyboard(!showKeyboard);
  };

  // Determine if the Next button should be enabled
  const isNextEnabled = () => {
    if (currentStep === "options") {
      return selectedOption !== null; // Only enable if an option is selected
    } else if (currentStep === "textarea") {
      return openEndedAnswer.trim().length > 0; // Only enable if answer is not empty
    } else if (currentStep === "review") {
      return true; // Always enabled for review step
    } else if (currentStep === "analysis") {
      return !isAnalyzing; // Only enable if not analyzing
    }
    return true; // Always enabled for other steps
  };

  // Get the appropriate button text based on current step
  const getButtonText = () => {
    if (currentStep === "textarea") {
      return "Submit";
    } else if (currentStep === "review") {
      return "Submit Review";
    } else if (currentStep === "analysis") {
      return isAnalyzing ? "Analyzing..." : "Close";
    }
    return "Next";
  };

  // Function to set the analysis result
  const setAnalysis = (data) => {
    setIsAnalyzing(false);
    setAnalysisResult(data);
    // Reset suggestion index when new data arrives
    setCurrentSuggestionIndex(0);
  };

  // Add function to handle moving to the next suggestion
  const handleNextSuggestion = () => {
    if (Array.isArray(analysisResult) && currentSuggestionIndex < analysisResult.length - 1) {
      setCurrentSuggestionIndex(prevIndex => prevIndex + 1);
    }
  };

  // If this component is rendered by PracticeModal, make the setAnalysis function available
  if (onComplete && !onComplete.setAnalysis) {
    onComplete.setAnalysis = setAnalysis;
  }

  // Define a CSS block directly in the component
  const fontStyles = `
    .montserrat-font {
      font-family: 'Montserrat-large' !important;
    }
    
    .text-color {
      color: #ffe6c8 !important;
    }
    
    .heading {
      margin-bottom: 1.5rem;
    }
    
    .textarea-custom {
      resize: none;
      color: #ffe6c8 !important;
      background-color: #343a40;
      border-color: #495057;
    }
    
    .option-text {
      color: #ffe6c8 !important;
    }
    
    .option-marker {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
    
    .keyboard-toggle {
      position: absolute;
      right: 15px;
      top: 15px;
      background-color: #343a40;
      border: 1px solid #495057;
      color: #ffe6c8;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
      z-index: 1;
    }
    
    .language-selector {
      margin-bottom: 10px;
    }
    
    .keyboard-container {
      margin-top: 1rem;
      background-color: #1a1a1a;
      border-radius: 4px;
      padding: 10px;
      max-height: 250px;
      overflow: auto;
    }
    
    .analyzing-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      text-align: center;
    }
    
    .spinner {
      border: 4px solid rgba(255, 230, 200, 0.3);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border-left-color: #ffe6c8;
      animation: spin 1s linear infinite;
      margin-bottom: 10px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    .analyzing-text {
      font-size: 16px;
      color: #ffe6c8;
      margin: 10px 0;
    }
    
    .analysis-content {
      text-align: left;
      max-height: 60vh;
      overflow-y: auto;
      padding-right: 10px;
      white-space: pre-wrap;
      line-height: 1.5;
    }
    
    /* Add styles for student suggestions */
    .student-suggestions {
      font-family: 'Montserrat-large', sans-serif;
      color: #ffe6c8;
      text-align: left;
      margin: 0 auto;
    }
    
    .student-suggestions h3 {
      font-size: 1.75rem;
      margin-bottom: 1rem;
      text-align: center;
      color: #f8d090;
      padding-bottom: 0.5rem;
    }
    
    .student-suggestions h4 {
      font-size: 1.25rem;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      color: #f8d090;
      border-left: 4px solid #f8d090;
      padding-left: 0.75rem;
    }
    
    .student-suggestions p {
      margin-bottom: 1rem;
      line-height: 1.6;
      font-size: 1rem;
    }
  `;

  // Render the correct content based on the current step
  const renderContent = () => {
    if (currentStep === "results") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Congratulations{userInfo?.firstName ? `, ${userInfo.firstName}` : ''}!</h2>
          <p className="mb-4 fs-5 montserrat-font text-color">You have successfully completed the practice.</p>
          
          <div className="stats-summary my-4 d-flex justify-content-around">
            <div className="stat-item text-center">
              <div className="stat-value fs-1 fw-bold text-success montserrat-font text-color">{typingSpeed?.wpm || 0}</div>
              <div className="stat-label montserrat-font text-color">WPM</div>
            </div>
            <div className="stat-item text-center">
              <div className="stat-value fs-1 fw-bold text-info montserrat-font text-color">{formatTime(elapsedTime)}</div>
              <div className="stat-label montserrat-font text-color">Time</div>
            </div>
            <div className="stat-item text-center">
              <div className="stat-value fs-1 fw-bold text-warning montserrat-font text-color">{attentionScore || 0}%</div>
              <div className="stat-label montserrat-font text-color">Focus</div>
            </div>
          </div>
          
          <p className="mb-4 montserrat-font text-color">Click next to continue to a question about what you learned.</p>
        </>
      );
    } else if (currentStep === "question") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Quick Question</h2>
          {questionData?.question ? (
            <div className="question-container bg-dark p-4 rounded mb-4">
              <p className="fs-5 mb-0 montserrat-font text-color">
                {typeof questionData.question === 'string' 
                  ? questionData.question 
                  : 'Question text unavailable'}
              </p>
            </div>
          ) : (
            <p className="fs-5 mb-4 montserrat-font text-color">No question available for this practice.</p>
          )}
          
          <p className="mb-4 montserrat-font text-color">Click next to see the available options.</p>
        </>
      );
    } else if (currentStep === "options") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Select Your Answer</h2>
          
          {questionData?.options && Array.isArray(questionData.options) && questionData.options.length > 0 ? (
            <div className="options-container mb-4">
              {questionData.options.map((option, index) => (
                <div 
                  key={index}
                  className={`option-item mb-3 p-3 rounded d-flex align-items-center ${selectedOption === index ? 'border border-primary' : ''}`}
                  style={{ 
                    backgroundColor: selectedOption === index ? 'rgba(13, 110, 253, 0.2)' : 'rgba(255, 255, 255, 0.1)',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleOptionSelect(index)}
                >
                  <div 
                    className={`option-marker me-3 rounded-circle d-flex justify-content-center align-items-center ${selectedOption === index ? 'bg-primary' : 'bg-secondary'} montserrat-font`}
                  >
                    <span className="montserrat-font text-color">{String.fromCharCode(65 + index)}</span>
                  </div>
                  <div className="option-text montserrat-font text-color">
                    {typeof option === 'string' ? option : JSON.stringify(option)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="fs-5 mb-4 montserrat-font text-color">No options available for this question.</p>
          )}
          
          <p className="mb-4 montserrat-font text-color">Select an option and click next to continue.</p>
        </>
      );
    } else if (currentStep === "openended") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Open-Ended Question</h2>
          
          {questionData?.openEndedQuestion ? (
            <div className="question-container bg-dark p-4 rounded mb-4">
              <p className="fs-5 mb-0 montserrat-font text-color">
                {typeof questionData.openEndedQuestion === 'string' 
                  ? questionData.openEndedQuestion 
                  : 'Question text unavailable'}
              </p>
            </div>
          ) : (
            <p className="fs-5 mb-4 montserrat-font text-color">Please share your thoughts about this practice session.</p>
          )}
          
          <p className="mb-4 montserrat-font text-color">Click next to provide your answer.</p>
        </>
      );
    } else if (currentStep === "textarea") {
      return (
        <>
          <style>{fontStyles}</style>
          <button 
            className="keyboard-toggle montserrat-font" 
            onClick={toggleKeyboard}
          >
            {showKeyboard ? 'Hide Keyboard' : 'Show Keyboard'}
          </button>
          <h2 className="montserrat-font text-color heading">Your Answer</h2>
          
          <div className="form-group mb-4">
            <textarea
              className="form-control bg-dark montserrat-font textarea-custom"
              rows="5"
              placeholder="Type your answer here..."
              value={openEndedAnswer}
              onChange={handleOpenEndedAnswerChange}
            ></textarea>
          </div>
          
          {showKeyboard && (
            <div className="keyboard-container">
              <div className="language-selector mb-2 d-flex justify-content-center">
                <select 
                  className="form-select montserrat-font"
                  style={{ maxWidth: "200px", backgroundColor: "#343a40", color: "#ffe6c8", border: "1px solid #495057" }}
                  value={keyboardLanguage}
                  onChange={(e) => setKeyboardLanguage(e.target.value)}
                >
                  <option value="English">English</option>
                  <option value="Hebrew">Hebrew</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
              <KeyboardManager 
                language={keyboardLanguage} 
                pressedKey={pressedKey} 
                isCorrect={isCorrect} 
              />
            </div>
          )}
          
          <p className="mb-4 montserrat-font text-color">Type your answer and click submit to continue.</p>
        </>
      );
    } else if (currentStep === "review") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Please Leave a Review{userInfo ? `, ${userInfo.firstName}` : ''}</h2>
          
          <div className="review-container bg-dark p-4 rounded mb-4">
            <div className="mb-4">
              <label className="form-label montserrat-font text-color">How would you rate this practice session? (1-5)</label>
              <div className="rating-container d-flex justify-content-center gap-2 mt-2">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <button
                    key={rating}
                    type="button"
                    className={`btn ${reviewRating === rating ? 'btn-primary' : 'btn-outline-light'} rounded-circle d-flex justify-content-center align-items-center montserrat-font`}
                    style={{ width: '40px', height: '40px' }}
                    onClick={() => setReviewRating(rating)}
                  >
                    {rating}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Add comprehension rating slider */}
            <div className="form-group mb-4">
              <label className="form-label montserrat-font text-color">
                How well did you comprehend the material? ({comprehensionRating}%)
              </label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="100"
                step="5"
                value={comprehensionRating}
                onChange={handleComprehensionChange}
              />
              <div className="d-flex justify-content-between">
                <small className="text-muted montserrat-font text-color">Poor</small>
                <small className="text-muted montserrat-font text-color">Excellent</small>
              </div>
            </div>
            
            <div className="form-group mb-4">
              <label className="form-label montserrat-font text-color">Any additional comments or feedback?</label>
              <textarea
                className="form-control bg-dark montserrat-font textarea-custom"
                rows="4"
                placeholder="Share your thoughts on this practice session..."
                value={reviewComment}
                onChange={handleReviewCommentChange}
              ></textarea>
            </div>
          </div>
          
          <p className="mb-4 montserrat-font text-color">Click submit to complete the practice session.</p>
        </>
      );
    } else if (currentStep === "analysis") {
      return (
        <>
          <style>{fontStyles}</style>
          <h2 className="montserrat-font text-color heading">Practice Analysis{userInfo?.firstName ? ` for ${userInfo.firstName}` : ''}</h2>
          
          {isAnalyzing ? (
            <div className="analyzing-container">
              <div className="spinner"></div>
              <p className="analyzing-text montserrat-font">Analyzing your practice session...</p>
              <p className="analyzing-text montserrat-font">This may take a few moments.</p>
            </div>
          ) : Array.isArray(analysisResult) ? (
            <div className="bg-dark p-4 rounded mb-4">
              <div className="student-suggestions">
                {currentSuggestionIndex === 0 && analysisResult[0].prefix && (
                  <p className="text-center mb-4" style={{ color: '#d0d0d0', fontStyle: 'italic' }}>{analysisResult[0].prefix}</p>
                )}
                
                <h3 className="text-center" style={{ color: '#f8d090', marginBottom: '1.5rem' }}>
                  {analysisResult[currentSuggestionIndex]?.title || "Suggestion"}
                </h3>
                
                <div className="p-3 rounded" style={{ 
                  backgroundColor: 'rgba(248, 208, 144, 0.1)', 
                  marginBottom: '1.5rem'
                }}>
                  <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                    {analysisResult[currentSuggestionIndex]?.content || "No suggestion available."}
                  </p>
                </div>
                
                {/* Navigation buttons */}
                <div className="d-flex justify-content-center mt-4 gap-3">
                  {currentSuggestionIndex < analysisResult.length - 1 ? (
                    <button 
                      className="btn px-4 py-2 montserrat-font"
                      onClick={handleNextSuggestion}
                      style={{
                        backgroundColor: '#f8d090',
                        color: '#343a40',
                        fontWeight: 'bold',
                        borderRadius: '30px'
                      }}
                    >
                      Next Suggestion
                    </button>
                  ) : (
                    <button 
                      className="btn px-4 py-2 montserrat-font"
                      onClick={handleClose}
                      style={{
                        backgroundColor: '#f8d090',
                        color: '#343a40',
                        fontWeight: 'bold',
                        borderRadius: '30px'
                      }}
                    >
                      Finish
                    </button>
                  )}
                </div>
                
                {/* Show progress indicators */}
                <div className="d-flex justify-content-center mt-3 mb-2">
                  {analysisResult.map((_, index) => (
                    <div 
                      key={index} 
                      className="mx-1" 
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        background: index === currentSuggestionIndex ? '#f8d090' : 'rgba(255, 230, 200, 0.3)'
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : typeof analysisResult === 'string' ? (
            <div className="bg-dark p-4 rounded mb-4">
              <div 
                className="analysis-content montserrat-font text-color"
                dangerouslySetInnerHTML={{ __html: analysisResult }}
              />
              
              {/* Close button for string content */}
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button 
                  className="btn btn-secondary px-4 montserrat-font"
                  onClick={handleTryAgain}
                  disabled={isAnalyzing}
                >
                  Try Again
                </button>
                <button 
                  className="btn btn-primary px-4 montserrat-font"
                  onClick={handleClose}
                  disabled={isAnalyzing}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <p className="mb-4 fs-5 montserrat-font text-color">No analysis is available.</p>
          )}
        </>
      );
    }
  };

  // Add handleTryAgain function to re-run the analysis
  const handleTryAgain = () => {
    // Set analyzing state
    setIsAnalyzing(true);
    
    // Create the same review data object again
    const reviewData = { 
      rating: reviewRating, 
      comment: reviewComment,
      comprehension: comprehensionRating,
      userInfo: userInfo || { firstName: '', lastName: '' },
      openEndedAnswer, 
      selectedOption,
      setAnalysis,
    };
    
    console.log("Try Again - Resending review data");
    
    // Call onComplete to update parent state and trigger API call again
    if (onComplete) {
      onComplete(selectedOption, openEndedAnswer, reviewData);
    }
  };

  // Add handleClose function to directly close the popup
  const handleClose = () => {
    console.log("Analysis step - Close button clicked");
    if (closeModal) {
      closeModal(); // Use the dedicated close function that bypasses API calls
    }
  };

  return (
    <div 
      className="completion-popup position-absolute d-flex flex-column justify-content-center align-items-center montserrat-font" 
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.85)",
        zIndex: 9999,
        color: "#ffe6c8",
        textAlign: "center",
        padding: "2rem"
      }}>
      <style>{fontStyles}</style>
      <div className="completion-content bg-dark p-5 rounded position-relative" style={{ 
        maxWidth: "600px", 
        width: "100%",
        maxHeight: currentStep === "textarea" && showKeyboard ? "90vh" : "80vh",
        overflowY: "auto"
      }}>
        {renderContent()}
        
        {/* Only show the Next/Submit button when not in the analysis step */}
        {currentStep !== "analysis" && (
          <button 
            className="btn btn-primary btn-lg px-5 montserrat-font"
            onClick={handleNext}
            disabled={!isNextEnabled()}
          >
            {getButtonText()}
          </button>
        )}
      </div>
    </div>
  );
}

export default CompletionPopup; 