import React from 'react';

export default function EnglishKeyboard({ pressedKey, isCorrect }) {
  const getKeyClass = (keyCode) => {
    if (pressedKey === keyCode) {
      return isCorrect ? 'highlight-correct' : 'highlight-wrong';
    }
    return '';
  };
  return (
    <figure className="kbBtnsWrap English montser-lrg">
      {/* Numerics Row */}
      <div className="kbBtnsRow numericsRow montser-mdm d-flex flex-row justify-content-center align-items-center w-100">
        <button
          className={`doubleSigns ${getKeyClass('192')}`}
          type="button"
          data-key-code-val="192"
          data-finger="babyFingerLeft"
        >
          <span className="shiftPress">~</span>
          <span className="normalPress">`</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('49')}`}
          type="button"
          data-key-code-val="49"
          data-finger="babyFingerLeft"
        >
          <span className="shiftPress">!</span>
          <span className="normalPress">1</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('50')}`}
          type="button"
          data-key-code-val="50"
          data-finger="ringFingerLeft"
        >
          <span className="shiftPress">@</span>
          <span className="normalPress">2</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('51')}`}
          type="button"
          data-key-code-val="51"
          data-finger="middleFingerLeft"
        >
          <span className="shiftPress">#</span>
          <span className="normalPress">3</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('52')}`}
          type="button"
          data-key-code-val="52"
          data-finger="indexFingerLeft"
        >
          <span className="shiftPress">$</span>
          <span className="normalPress">4</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('53')}`}
          type="button"
          data-key-code-val="53"
          data-finger="indexFingerLeft"
        >
          <span className="shiftPress">%</span>
          <span className="normalPress">5</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('54')}`}
          type="button"
          data-key-code-val="54"
          data-finger="ringFingerRight"
        >
          <span className="shiftPress">^</span>
          <span className="normalPress">6</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('55')}`}
          type="button"
          data-key-code-val="55"
          data-finger="ringFingerRight"
        >
          <span className="shiftPress">&amp;</span>
          <span className="normalPress">7</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('56')}`}
          type="button"
          data-key-code-val="56"
          data-finger="middleFingerRight"
        >
          <span className="shiftPress">*</span>
          <span className="normalPress">8</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('57')}`}
          type="button"
          data-key-code-val="57"
          data-finger="indexFingerRight"
        >
          <span className="shiftPress">(</span>
          <span className="normalPress">9</span>
        </button>
        <button
          className={`signNumeric ${getKeyClass('48')}`}
          type="button"
          data-key-code-val="48"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">)</span>
          <span className="normalPress">0</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('173')}`}
          type="button"
          data-key-code-val="173"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">_</span>
          <span className="normalPress">-</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('61')}`}
          type="button"
          data-key-code-val="61"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">+</span>
          <span className="normalPress">=</span>
        </button>
        <button
          className={`kbHero backspaceBtn ${getKeyClass('8')}`}
          type="button"
          data-key-code-val="8"
          data-finger="babyFingerRight"
        >
          Backspace
        </button>
      </div>

      {/* Tab Row */}
      <div className="kbBtnsRow tabRow montser-mdm d-flex flex-row justify-content-center align-items-center">
        <button
          className={`kbHero tabBtn ${getKeyClass('9')}`}
          type="button"
          data-key-code-val="9"
          data-finger="babyFingerLeft"
        >
          Tab
        </button>
        <button
          className={`alphaBtn ${getKeyClass('81')}`}
          type="button"
          data-key-code-val="81"
          data-finger="babyFingerLeft"
        >
          Q
        </button>
        <button
          className={`alphaBtn ${getKeyClass('87')}`}
          type="button"
          data-key-code-val="87"
          data-finger="ringFingerLeft"
        >
          W
        </button>
        <button
          className={`alphaBtn ${getKeyClass('69')}`}
          type="button"
          data-key-code-val="69"
          data-finger="middleFingerLeft"
        >
          E
        </button>
        <button
          className={`alphaBtn ${getKeyClass('82')}`}
          type="button"
          data-key-code-val="82"
          data-finger="indexFingerLeft"
        >
          R
        </button>
        <button
          className={`alphaBtn ${getKeyClass('84')}`}
          type="button"
          data-key-code-val="84"
          data-finger="indexFingerLeft"
        >
          T
        </button>
        <button
          className={`alphaBtn ${getKeyClass('89')}`}
          type="button"
          data-key-code-val="89"
          data-finger="ringFingerRight"
        >
          Y
        </button>
        <button
          className={`alphaBtn ${getKeyClass('85')}`}
          type="button"
          data-key-code-val="85"
          data-finger="ringFingerRight"
        >
          U
        </button>
        <button
          className={`alphaBtn ${getKeyClass('73')}`}
          type="button"
          data-key-code-val="73"
          data-finger="middleFingerRight"
        >
          I
        </button>
        <button
          className={`alphaBtn ${getKeyClass('79')}`}
          type="button"
          data-key-code-val="79"
          data-finger="indexFingerRight"
        >
          O
        </button>
        <button
          className={`alphaBtn ${getKeyClass('80')}`}
          type="button"
          data-key-code-val="80"
          data-finger="babyFingerRight"
        >
          P
        </button>
        <button
          className={`doubleSigns ${getKeyClass('219')}`}
          type="button"
          data-key-code-val="219"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">{'{'}</span>
          <span className="normalPress">[</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('221')}`}
          type="button"
          data-key-code-val="221"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">{'}'}</span>
          <span className="normalPress">]</span>
        </button>
        <button
          className={`doubleSigns backSlash ${getKeyClass('220')}`}
          type="button"
          data-key-code-val="220"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">|</span>
          <span className="normalPress">\</span>
        </button>
      </div>

      {/* Caps Lock Row */}
      <div className="kbBtnsRow capsLockRow montser-mdm  d-flex flex-row justify-content-center align-items-center">
        <button
          className={`kbHero capLockBtn ${getKeyClass('20')}`}
          type="button"
          data-key-code-val="20"
          data-finger="babyFingerLeft"
        >
          Caps Lock
        </button>
        <button
          className={`alphaBtn ${getKeyClass('65')}`}
          type="button"
          data-key-code-val="65"
          data-finger="babyFingerLeft"
        >
          A
        </button>
        <button
          className={`alphaBtn ${getKeyClass('83')}`}
          type="button"
          data-key-code-val="83"
          data-finger="ringFingerLeft"
        >
          S
        </button>
        <button
          className={`alphaBtn ${getKeyClass('68')}`}
          type="button"
          data-key-code-val="68"
          data-finger="middleFingerLeft"
        >
          D
        </button>
        <button
          className={`alphaBtn ${getKeyClass('70')}`}
          type="button"
          data-key-code-val="70"
          data-finger="indexFingerLeft"
        >
          F
        </button>
        <button
          className={`alphaBtn ${getKeyClass('71')}`}
          type="button"
          data-key-code-val="71"
          data-finger="indexFingerLeft"
        >
          G
        </button>
        <button
          className={`alphaBtn ${getKeyClass('72')}`}
          type="button"
          data-key-code-val="72"
          data-finger="ringFingerRight"
        >
          H
        </button>
        <button
          className={`alphaBtn ${getKeyClass('74')}`}
          type="button"
          data-key-code-val="74"
          data-finger="ringFingerRight"
        >
          J
        </button>
        <button
          className={`alphaBtn ${getKeyClass('75')}`}
          type="button"
          data-key-code-val="75"
          data-finger="middleFingerRight"
        >
          K
        </button>
        <button
          className={`alphaBtn ${getKeyClass('76')}`}
          type="button"
          data-key-code-val="76"
          data-finger="indexFingerRight"
        >
          L
        </button>
        <button
          className={`doubleSigns ${getKeyClass('59')}`}
          type="button"
          data-key-code-val="59"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">:</span>
          <span className="normalPress">;</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('222')}`}
          type="button"
          data-key-code-val="222"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">"</span>
          <span className="normalPress">'</span>
        </button>
        <button
          className={`kbHero enterBtn ${getKeyClass('13')}`}
          type="button"
          data-key-code-val="13"
          data-finger="babyFingerRight"
        >
          Enter
        </button>
      </div>

      {/* Shift Row */}
      <div className="kbBtnsRow shiftRow montser-mdm d-flex flex-row  justify-content-center align-items-center">
        <button
          className={`kbHero firstShiftBtn ${getKeyClass('16')}`}
          type="button"
          data-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
        <button
          className={`alphaBtn ${getKeyClass('90')}`}
          type="button"
          data-key-code-val="90"
          data-finger="babyFingerLeft"
        >
          Z
        </button>
        <button
          className={`alphaBtn ${getKeyClass('88')}`}
          type="button"
          data-key-code-val="88"
          data-finger="ringFingerLeft"
        >
          X
        </button>
        <button
          className={`alphaBtn ${getKeyClass('67')}`}
          type="button"
          data-key-code-val="67"
          data-finger="middleFingerLeft"
        >
          C
        </button>
        <button
          className={`alphaBtn ${getKeyClass('86')}`}
          type="button"
          data-key-code-val="86"
          data-finger="indexFingerLeft"
        >
          V
        </button>
        <button
          className={`alphaBtn ${getKeyClass('66')}`}
          type="button"
          data-key-code-val="66"
          data-finger="indexFingerLeft"
        >
          B
        </button>
        <button
          className={`alphaBtn ${getKeyClass('78')}`}
          type="button"
          data-key-code-val="78"
          data-finger="ringFingerRight"
        >
          N
        </button>
        <button
          className={`alphaBtn ${getKeyClass('77')}`}
          type="button"
          data-key-code-val="77"
          data-finger="ringFingerRight"
        >
          M
        </button>
        <button
          className={`doubleSigns ${getKeyClass('188')}`}
          type="button"
          data-key-code-val="188"
          data-finger="middleFingerRight"
        >
          <span className="shiftPress">&lt;</span>
          <span className="normalPress">,</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('190')}`}
          type="button"
          data-key-code-val="190"
          data-finger="indexFingerRight"
        >
          <span className="shiftPress">&gt;</span>
          <span className="normalPress">.</span>
        </button>
        <button
          className={`doubleSigns ${getKeyClass('191')}`}
          type="button"
          data-key-code-val="191"
          data-finger="babyFingerRight"
        >
          <span className="shiftPress">?</span>
          <span className="normalPress">/</span>
        </button>
        <button
          className={`kbHero secondShiftBtn ${getKeyClass('16')}`}
          type="button"
          data-key-code-val="16"
          data-finger="babyFinger"
        >
          Shift
        </button>
      </div>

      {/* Space Row */}
      <div className="kbBtnsRow spaceRow montser-mdm d-flex flex-row   justify-content-center align-items-center">
        <button
          className={`kbHero spaceBtn ${getKeyClass('32')}`}
          type="button"
          data-key-code-val="32"
          data-finger="thumb"
        />
      </div>
    </figure>
  );
}