import React, { useState, useEffect, useLayoutEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import axios from 'axios';

const Navbar = ({ bgColorClass }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  
  // Check localStorage for saved theme preference, default to dark mode if none exists
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'light' ? false : true; // Default to dark if no preference or 'dark'
  });

  // Initialize these AFTER we know the dark mode state
  const [bgColor, setBgColor] = useState('var(--dark-bg-main)');
  const [fontColor, setFontColor] = useState(darkMode ? 'var(--text-primary)' : 'var(--text-primary)');

  const subDomain = localStorage.getItem('subDomain');
  const herobgColor = localStorage.getItem("herobgColor");
  const herofontColor = localStorage.getItem("heroFontColor");
  let schoolName = subDomain;
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Use useLayoutEffect to ensure dark mode is applied before first render
  useLayoutEffect(() => {
    // Apply the theme based on darkMode state - this sets the CSS variables through the body class
    if (darkMode) {
      document.body.classList.remove('light-mode');
      setBgColor('var(--bg-main)'); // Using dynamic variable that responds to theme
    } else {
      document.body.classList.add('light-mode');
      setBgColor('var(--bg-main)'); // Using dynamic variable that responds to theme
    }
    // Set text color based on the current theme
    setFontColor('var(--text-primary)');
  }, [darkMode]); // Re-run when darkMode changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          setBgColor(response.data.backgroundColor);
          setFontColor(response.data.fontColor);
  
          const logoUrl = response.data.logo.logo;
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl, response.data.fontColor);  // Pass fontColor here
          } else {
            setLogoContent(logoUrl);  // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        setError("Error loading data");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [schoolName]);
  
  const fetchAndModifySVG = async (svgUrl, fontColor) => {  
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', fontColor);   // Use the passed fontColor
          path.setAttribute('stroke', fontColor); 
        });
        svgElement.setAttribute('width', '60px');
        svgElement.setAttribute('height', '30px');
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrolling(currentScrollPos > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
      if (openDropdownIndex !== null && !e.target.closest('.dropdown-menu') && !e.target.closest('.nav-item')) {
        setOpenDropdownIndex(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed, openDropdownIndex]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleJoinClick = () => {
    if(subDomain=='Contact Center Academy'){
      const loginLink = `https://contactcenter.app.virtupreplabs.com/join`;
      window.open(loginLink, '_blank');
    }else if(subDomain=="virtuprep"||subDomain=="virtupreplabs"){
      const loginLink = 'https://learning.virtupreplabs.com';
      window.open(loginLink, '_blank');
    }
    else{
      const joinLink = `https://${subDomain}.app.virtupreplabs.com/join`;
      window.open(joinLink, '_blank');
    };
  };

  const redirectProtocol = (name) => {
    console.log("redirectProtocol clickedd==>",name)
    if(name == "Protocol"){
      const link = `https://realmeritprotocol.com/docs/`;
      window.open(link, '_blank');
    }else{
      const link = `https://learningwithoutgatekeepers.com/`;
      window.open(link, '_blank');
    }
    
  };

  const handleLoginClick = () => {
    if(subDomain=='Contact Center Academy'){
    const loginLink = `https://contactcenter.app.virtupreplabs.com`;
    window.open(loginLink, '_blank');
    }else if(subDomain=="virtuprep"||subDomain=="virtupreplabs"){
      const loginLink = 'https://learning.virtupreplabs.com';
      window.open(loginLink, '_blank');
    }
    else{
      const loginLink = `https://${subDomain}.app.virtupreplabs.com`;
      window.open(loginLink, '_blank');
    }
    
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    
    // Save preference to localStorage
    localStorage.setItem('theme', newDarkMode ? 'dark' : 'light');
    
    // Toggle the light-mode class on the document body
    // The CSS variables will be updated through the body class
    if (newDarkMode) {
      // Switching to dark mode
      document.body.classList.remove('light-mode');
    } else {
      // Switching to light mode
      document.body.classList.add('light-mode');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <>
      {/* Dark Mode Toggle - Fixed position on right side */}
      <div 
        onClick={toggleDarkMode}
        className="theme-toggle"
        style={{ 
          position: 'fixed',
          top: '20px',
          right: '20px',
          background: darkMode ? 'var(--dark-bg-main)' : 'var(--light-accent-primary)',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          width: '40px',
          height: '20px',
          borderRadius: '20px',
          padding: 0,
          boxShadow: '0 1px 3px rgba(0,0,0,0.3)',
          zIndex: 1040,
          transition: 'background-color 0.3s ease'
        }}
        aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
      >
        {/* Dark mode background with moon icon */}
        <div 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 5px',
            opacity: darkMode ? 1 : 0,
            transition: 'opacity 0.3s ease'
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="var(--dark-text-primary)" viewBox="0 0 16 16">
            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
          </svg>
        </div>

        {/* Light mode background with sun icon */}
        <div 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0 5px',
            opacity: darkMode ? 0 : 1,
            transition: 'opacity 0.3s ease'
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="var(--light-bg-main)" viewBox="0 0 16 16">
            <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13z"/>
          </svg>
        </div>
        
        {/* Toggle Slider Circle */}
        <div style={{
          background: 'var(--bg-secondary)',
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          position: 'absolute',
          left: darkMode ? '2px' : 'calc(100% - 18px)',
          top: '2px',
          transition: 'left 0.3s ease',
          boxShadow: '0 1px 2px rgba(0,0,0,0.2)'
        }}></div>
      </div>

      <div className="vertical-navbar" style={{ 
        backgroundColor: bgColor || 'var(--dark-bg-main)',
        color: fontColor,
        width: '250px',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        zIndex: 1030
      }}>
        {/* Logo container */}
        <div className="logo-container mb-4 text-center">
          {subDomain === "virtupreplabs" ? (
            <a href="/" className="d-flex align-items-center justify-content-center" style={{ textDecoration: 'none' }}>
              <img src="/logos/VirtuPrepIcon.svg" alt="Virtu Prep Logo" width="35px" className="svg-rotate" style={{ verticalAlign: 'middle', marginRight: '15px' }} />
              <span className="montser-lrg text-virtu-header" style={{ fontSize: '1.2rem' }}>Virtu Prep</span>
            </a>
          ) : (
            logoContent ? (
              logoContent.includes('<svg') ? (
                <div
                  dangerouslySetInnerHTML={{ __html: logoContent }}
                  className="svg-rotate"
                />
              ) : (
                <img src={logoContent} alt="Logo" width="120px" />
              )
            ) : (
              <i className={data.logo?.iconClass || "icon-site-logo-"}></i>
            )
          )}
        </div>
        
        {/* About Us link with icon */}
        <a href="/about-us" className="nav-link mb-3 d-flex align-items-center" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          <span>About Us</span>
        </a>
        
        {/* Users link with icon */}
        <a href="/users" className="nav-link mb-3 d-flex align-items-center" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
          </svg>
          <span>Users</span>
        </a>
        
        {/* Features link with icon */}
        <a href="#features" className="nav-link mb-3 d-flex align-items-center" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
          </svg>
          <span>Features</span>
        </a>
        
        {/* Research link with icon - Updated to microscope profile */}
        <a href="/research" className="nav-link mb-3 d-flex align-items-center" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M8 1a7 7 0 0 0-7 7v1h1v-1a6 6 0 0 1 6-6 6 6 0 0 1 6 6v1h1v-1a7 7 0 0 0-7-7z"/>
            <path d="M9 10a1 1 0 0 1-2 0V7.934A1.97 1.97 0 0 1 6.5 8 1.5 1.5 0 0 1 5 6.5a1.5 1.5 0 1 1 3 0A1.97 1.97 0 0 1 7.5 7h.001v2.5a.5.5 0 0 0 1 0V7.5A1.5 1.5 0 0 1 9 6a1.5 1.5 0 0 1 3 0 1.5 1.5 0 0 1-1.5 1.5 1.97 1.97 0 0 1-.5-.066V10a1 1 0 0 1-1 1zm1.5-8A.5.5 0 0 0 10 2.5a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5z"/>
            <path d="M10.5 3.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5z"/>
          </svg>
          <span>Research</span>
        </a>
        
        {/* Writing link with icon */}
        <a href="/blogdashboard" className="nav-link mb-3 d-flex align-items-center" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
          </svg>
          <span>Writing</span>
        </a>
        
        {/* Protocol link with icon - Shield */}
        <a href="#" className="nav-link mb-3 d-flex align-items-center" onClick={() => redirectProtocol('Protocol')} style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
          </svg>
          <span>Protocol</span>
        </a>
        
        {/* Handbook link with icon - Book */}
        <a href="#" className="nav-link mb-3 d-flex align-items-center" onClick={() => redirectProtocol('Handbook')} style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
          </svg>
          <span>Handbook</span>
        </a>
        
        {/* Advocacy link with icon - Updated to government building */}
        <a href="https://merit.institute" className="nav-link mb-3 d-flex align-items-center" target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
          </svg>
          <span>Advocacy</span>
        </a>
        
        {/* Spacer to push login/join to bottom */}
        <div className="flex-grow-1"></div>
        
        {/* Login link with icon */}
        <a href="#" className="nav-link mb-3 d-flex align-items-center" onClick={handleLoginClick} style={{ paddingLeft: '1vw' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
            <path fillRule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
          </svg>
          <span>Login</span>
        </a>
        
        {/* Join Now button */}
        <a href="#" 
          className="join-button text-center py-2 mb-3 d-flex align-items-center justify-content-center" 
          style={{ 
            backgroundColor: "#6c9",
            color: "#1c2933",
            borderRadius: "5px",
            textDecoration: "none",
            marginLeft: "1vw",
            marginRight: "1vw"
          }} 
          onClick={handleJoinClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          <span>Join Now</span>
        </a>
        
        {/* Copyright notice */}
        <small className="text-virtu-header mt-2 text-center">
          Virtu Prep © 2015-2023
        </small>
      </div>
    </>
  );
};

export default Navbar;
