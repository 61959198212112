import React, { useEffect, useRef, useState } from 'react';
import '../global.css';
import './videobackground.css';

const VideoBackground = ({ videoLink, imageLink, overlayText, buttonText, fontColor, backgroundColor }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isJoinHovered, setIsJoinHovered] = useState(false); // Hover state for the second button

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleJoinMouseEnter = () => {
    setIsJoinHovered(true);
  };

  const handleJoinMouseLeave = () => {
    setIsJoinHovered(false);
  };

  const buttonStyle = {
    backgroundColor: isHovered ? 'var(--bg-main)' : 'var(--text-primary)',
    color: isHovered ? 'var(--text-primary)' : 'var(--bg-main)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const joinButtonStyle = {
    backgroundColor: isJoinHovered ? 'var(--bg-main)' : 'var(--accent-primary, #83B06D)',
    color: isJoinHovered ? 'var(--accent-primary, #83B06D)' : 'var(--bg-main)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const handleClick = () => {
    const destinationUrl = buttonText === 'Home' ? '/' : '/about-us';
    window.location.href = destinationUrl;
  };

  const subDomain = localStorage.getItem('subDomain');

  const handleJoinClick = () => {
    if(subDomain=='Contact Center Academy'){
      const loginLink = `https://contactcenter.app.virtupreplabs.com/join`;
      window.open(loginLink, '_blank');
    }else{
      const joinLink = `https://${subDomain}.app.virtupreplabs.com/join`;
      window.open(joinLink, '_blank');
    }
    
  };

  function splitTextByWordCount() {
    if (!overlayText) {
      return ['', ''];
    }
    const words = overlayText.split(' ');
    const midPoint = Math.floor(words.length / 2);
    return [words.slice(0, midPoint).join(' '), words.slice(midPoint).join(' ')];
  }

  const [firstLine, secondLine] = splitTextByWordCount();

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner p-2">
        <div className="carousel-item active">
          <div className='h-100 w-100 position-absolute' style={{ backgroundColor: backgroundColor, opacity: '100%' }}>
            <div className="media-overlay">
              {/* Conditionally render video or image */}
              {videoLink ? (
                <video ref={videoRef} autoPlay loop muted playsInline className="w-100">
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : imageLink ? (
                <img src={imageLink} alt="Background" className="w-100 h-100" style={{width:'75%'}}/>
              ) : null}
            </div>
          </div>
          <div className="position-relative text-center" style={{ top: '10rem' }}>
            <div>
            <h1 className="custom-large-heading common-textshadow mobile-heading montser-mdm-italic ft-5vh">
              {firstLine}
            </h1>
            <h1 className="custom-large-heading common-textshadow mobile-heading montser-mdm-italic ft-5vh mt-3">
              {secondLine}
            </h1>
            </div>
            <div className="about">
              <button
                type="button"
                className="py-2 rounded-top rounded-0 btn-lg btn-block montser-lrg common-textshadow common-boxshadow"
                onClick={handleClick}
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {buttonText}
              </button>
              <button
                type="button"
                className="py-2 rounded-top rounded-0 btn-block montser-lrg mt-5 btn-lg common-textshadow common-boxshadow"
                style={joinButtonStyle}
                onMouseEnter={handleJoinMouseEnter}
                onMouseLeave={handleJoinMouseLeave}
                onClick={handleJoinClick}
              >
                Join
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
