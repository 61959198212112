import React, { useState, useEffect, useCallback, useRef } from "react";
import * as mediapipe from "@mediapipe/face_mesh";
import * as camera_utils from "@mediapipe/camera_utils";
import * as drawing_utils from "@mediapipe/drawing_utils";

function FaceTracker({ isActive, onAttentionChange }) {
  const [isFaceTracking, setIsFaceTracking] = useState(false);
  const [facePosition, setFacePosition] = useState({ x: 0, y: 0 });
  const [attentionScore, setAttentionScore] = useState(100);
  const [cameraPermission, setCameraPermission] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const faceMeshRef = useRef(null);
  const cameraRef = useRef(null);

  // Function to setup MediaPipe face tracking
  const setupFaceTracking = useCallback(async () => {
    if (!isActive || !videoRef.current || !canvasRef.current) return;
    
    try {
      // Request camera permission
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 320,
          height: 240,
          facingMode: "user"
        }
      });
      
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCameraPermission(true);
        console.log("Camera permission granted");
      }
      
      // Setup FaceMesh
      faceMeshRef.current = new mediapipe.FaceMesh({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        }
      });
      
      faceMeshRef.current.setOptions({
        maxNumFaces: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      
      // Setup callback for face detection results
      faceMeshRef.current.onResults(onFaceDetectionResults);
      
      // Initialize camera
      if (videoRef.current) {
        cameraRef.current = new camera_utils.Camera(videoRef.current, {
          onFrame: async () => {
            if (faceMeshRef.current && videoRef.current) {
              await faceMeshRef.current.send({ image: videoRef.current });
            }
          },
          width: 320,
          height: 240
        });
        
        cameraRef.current.start();
        setIsFaceTracking(true);
        console.log("Face tracking initialized");
      }
    } catch (error) {
      console.error("Error initializing face tracking:", error);
      setCameraPermission(false);
    }
  }, [isActive]);
  
  // Handler for face detection results
  const onFaceDetectionResults = useCallback((results) => {
    if (!results || !results.multiFaceLandmarks || !canvasRef.current) return;
    
    const canvasCtx = canvasRef.current.getContext("2d");
    if (!canvasCtx) return;
    
    // Clear canvas
    canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    
    // Draw face landmarks
    if (results.multiFaceLandmarks.length > 0) {
      // Get the first face
      const face = results.multiFaceLandmarks[0];
      
      // Calculate face position (center point)
      const centerX = face[1].x * canvasRef.current.width;
      const centerY = face[1].y * canvasRef.current.height;
      setFacePosition({ x: centerX, y: centerY });
      
      // Calculate attention score based on face position
      // (Simple implementation: higher score when face is centered)
      const screenCenterX = canvasRef.current.width / 2;
      const screenCenterY = canvasRef.current.height / 2;
      const distanceFromCenter = Math.sqrt(
        Math.pow(centerX - screenCenterX, 2) + 
        Math.pow(centerY - screenCenterY, 2)
      );
      
      // Normalize to 0-100 score (100 is perfect attention)
      const maxDistance = Math.sqrt(
        Math.pow(canvasRef.current.width / 2, 2) + 
        Math.pow(canvasRef.current.height / 2, 2)
      );
      
      const newAttentionScore = Math.max(0, 100 - (distanceFromCenter / maxDistance) * 100);
      const roundedScore = Math.round(newAttentionScore);
      setAttentionScore(roundedScore);
      
      // Call the parent callback with the attention score
      if (onAttentionChange) {
        onAttentionChange(roundedScore);
      }
      
      // Draw face mesh
      drawing_utils.drawConnectors(
        canvasCtx, face, mediapipe.FACEMESH_TESSELATION,
        { color: "#00FF00", lineWidth: 1 }
      );
    } else {
      // No face detected, set low attention score
      setAttentionScore(0);
      if (onAttentionChange) {
        onAttentionChange(0);
      }
    }
  }, [onAttentionChange]);
  
  // Setup face tracking when component becomes active
  useEffect(() => {
    if (isActive) {
      setupFaceTracking();
    } else {
      // Cleanup camera resources when component becomes inactive
      if (cameraRef.current) {
        cameraRef.current.stop();
      }
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
      setIsFaceTracking(false);
    }
    
    // Cleanup function to ensure resources are released
    return () => {
      if (cameraRef.current) {
        cameraRef.current.stop();
      }
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
    };
  }, [isActive, setupFaceTracking]);

  return (
    <div className="face-tracker">
      <div className="position-relative" style={{ width: "100%", height: "150px" }}>
        <video
          ref={videoRef}
          style={{ 
            width: "100%", 
            height: "100%", 
            transform: "scaleX(-1)",
            display: "block"  // Hidden video element
          }}
        />
        <canvas
          ref={canvasRef}
          width="160"
          height="120"
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ transform: "scaleX(-1)" }}
        />
      </div>
      
      {/* Attention score */}
      <div className="mt-3 text-white text-center w-100">
        <div className="montser-mdm">Attention</div>
        <div className="progress mt-1" style={{ height: "10px" }}>
          <div 
            className={`progress-bar ${attentionScore > 70 ? 'bg-success' : attentionScore > 40 ? 'bg-warning' : 'bg-danger'}`}
            role="progressbar" 
            style={{ width: `${attentionScore}%` }}
            aria-valuenow={attentionScore} 
            aria-valuemin="0" 
            aria-valuemax="100"
          ></div>
        </div>
        <div className="montser-mdm mt-1">{attentionScore}%</div>
      </div>
      
      {!cameraPermission && (
        <button 
          className="btn btn-outline-light btn-sm mt-3"
          onClick={setupFaceTracking}
        >
          Enable Face Tracking
        </button>
      )}
    </div>
  );
}

export default FaceTracker; 